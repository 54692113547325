import tw, { css, theme } from 'twin.macro'

import {
  TeamSlider as TeamSliderTypes,
  TeamProps as TeamPropsTypes,
} from '@/types/team'

import { TeamBox } from '@/atoms/team-box'
import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { Slider, SliderItem } from '@/atoms/slider'

import { useMediaQuery } from '@/hooks/use-media-query'
import { useWebsite } from '@/hooks/use-website'

const styles = {
  container: (website: string) => [
    tw`py-16 bg-theme-secondary`,
    ['the-palm-beach', 'crockfords'].includes(website) && tw`bg-theme-gold bg-diamonds-pattern`,
  ],
  slider: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`pt-16 pb-4 flex -mr-5 overflow-y-auto`,
    isLargeScreen && itemsLength <= 4 && tw`!overflow-x-hidden`,
  ],
  sliderItem: [
    tw`flex-shrink-0 mx-3
      (width[calc(100vw - 40%)])
    `,
    css`
      @media (min-width: ${theme`screens.md`}) {
        width: calc(calc(100% / 3) - ${theme`gap.5`});
      }
      @media (min-width: ${theme`screens.xl`}) {
        width: calc(calc(${theme`screens.xl`} / 4) - ${theme`gap.5`});
      }
    `,
  ],
  role: (website: string) => [
    tw`text-white`,
    website === 'the-palm-beach' && tw`text-black`,
  ],
}

function TeamSlider({ data }: { data: TeamSliderTypes }) {
  const website = useWebsite()
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`)

  const { heading, strapline, teams } = data

  return (
    <Container variant="full" as="section" css={styles.container(website)}>
      <Container>
        <Heading as="h3" variant="h2" tw="mb-2 text-white leading-none">
          {heading}
        </Heading>
        <p css={[
          tw`leading-6 uppercase font-light text-xl tracking-wide text-white`,
          website === 'crockfords' && tw`uppercase`
        ]}>
          {strapline}
        </p>

        <Slider
          css={styles.slider({
            isLargeScreen,
            itemsLength: teams.data.length,
          })}>
          {teams.data.map((item: TeamPropsTypes) => (
            <SliderItem key={item.id} css={styles.sliderItem}>
              <div>
                <TeamBox {...item.attributes}>
                  <TeamBox.Image {...item.attributes} />
                  <TeamBox.Role>
                    <p css={styles.role(website)}>{item.attributes.role}</p>
                  </TeamBox.Role>
                </TeamBox>
              </div>
            </SliderItem>
          ))}
        </Slider>
      </Container>
    </Container>
  )
}

export { TeamSlider }
