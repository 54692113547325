import tw from 'twin.macro'

import {
  TeamSlider as TeamSliderTypes,
  TeamProps as TeamPropsTypes,
} from '@/types/team'

import { Container } from '@/atoms/container'
import { TeamBox } from '@/atoms/team-box'

function TeamGrid({ data }: { data: TeamSliderTypes }) {
  const { teams } = data

  return (
    <Container tw="grid grid-cols-1 xs:(grid-cols-2 gap-x-6) md:(grid-cols-3 gap-x-10) lg:gap-x-20 2xl:gap-x-44 gap-y-10 py-14 border-b">
      {teams.data.map((item: TeamPropsTypes) => (
        <TeamBox {...item.attributes}>
          <TeamBox.Image {...item.attributes} />
          <TeamBox.Role>
            <p tw="text-black">{item.attributes.role}</p>
          </TeamBox.Role>
        </TeamBox>
      ))}
    </Container>
  )
}

export { TeamGrid }
