import { useEffect, useRef } from 'react'
import tw, { css } from 'twin.macro'

import { SortOptions } from '@/types/sort-options'
import { Shop as ShopType } from '@/types/shops'

import { useMediaQuery } from '@/hooks/use-media-query'

import { SlideOver } from '@/atoms/slide-over'
import { SortIcon } from '@/atoms/sort-icon'
import { Button } from '@/atoms/button'
import { Heading } from '@/atoms/headings'
import { Icon } from '@/atoms/icon'

type Props = {
  isOpen: boolean
  setOpen: Function
  sortOptions: SortOptions
  sortBy: string
  setSortBy: Function
  shops: ShopType[]
  setShops: Function
  previousOption: { current: string | null }
}

const styles = {
  root: [
    tw`flex flex-col flex-1`,
    css`
      background-color: #f9f9f9;
    `,
  ],
  title: tw`flex justify-start items-center text-theme-accent py-5`,
  close: tw`ml-auto px-5 py-4`,
  content: tw`flex-1`,
  list: tw``,
  item: tw`border-b border-gray-800 border-opacity-20 overflow-hidden`,
  option: [
    tw`w-full flex justify-start items-center px-5 py-4.5 text-sm text-theme-inverse`,
    css`
      background-color: #f9f9f9;
    `,
  ],
  optionTitle: tw`flex-shrink-0 mr-auto`,
  optionRadio: tw`flex-shrink-0 w-3 h-3 border-theme-secondary`,
}

function ShopsMobileSortPanel({
  isOpen,
  setOpen,
  sortOptions,
  sortBy,
  setSortBy,
  shops,
  setShops,
  previousOption,
}: Props) {
  const isLargeScreen = useMediaQuery('(min-width: 1024px)')

  useEffect(() => {
    if (isOpen && isLargeScreen) {
      setOpen(false)
    }
  }, [isOpen, isLargeScreen])

  // set filters
  const setMobileFilter = () => {
    setOpen(false)
    if (sortBy === 'name_asc')
      return setShops(
        shops.sort((a: ShopType, b: ShopType) =>
          a.attributes.title.localeCompare(b.attributes.title)
        )
      )
    if (sortBy === 'name_desc')
      return setShops(
        shops.sort((a: ShopType, b: ShopType) =>
          b.attributes.title.localeCompare(a.attributes.title)
        )
      )
  }

  // clear filters
  const resetMobileFilter = () => {
    setSortBy(previousOption.current)
    setOpen(false)
  }

  return (
    <SlideOver isOpen={isOpen} position="right" id="mobile-sort-panel">
      <div css={styles.root}>
        <div css={styles.title}>
          <SortIcon className="w-6 h-6 mx-5" />

          <Heading variant="h3" tw="mr-auto">
            Sort By
          </Heading>

          <button css={styles.close} onClick={resetMobileFilter}>
            <Icon icon="close" variant="outline" tw="w-3.5 h-3.5" />
          </button>
        </div>

        <div css={styles.content}>
          <ul css={styles.list}>
            {Object.keys(sortOptions).map((option, optionIndex) => (
              <li css={styles.item} key={optionIndex}>
                <label css={styles.option}>
                  <span css={styles.optionTitle}>
                    {sortOptions[option].title}
                  </span>
                  <input
                    css={styles.optionRadio}
                    type="radio"
                    name="Sort"
                    checked={sortBy === option}
                    onChange={() => setSortBy(option)}
                  />
                </label>
              </li>
            ))}
          </ul>
        </div>

        <Button
          theme="accent"
          variant="contained"
          tw="my-20 mx-5 text-sm font-light tracking-wide leading-5.5"
          onClick={setMobileFilter}>
          Apply
        </Button>
      </div>
    </SlideOver>
  )
}

export { ShopsMobileSortPanel }
