import Image from 'next/image'

import tw, { css } from 'twin.macro'
import { Disclosure, Transition } from '@headlessui/react'

import { Image as ImageType } from '@/types/image'

import { RenderMarkdown } from '@/lib/render-markdown'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'

import { useWebsite } from '@/hooks/use-website'

import { ChevronDownIcon } from '@heroicons/react/solid'
import { ChevronUpIcon } from '@heroicons/react/solid'

function Menu({
  data,
  id,
}: {
  data: {
    menu: {
      data: {
        attributes: {
          copy: string
          title: string
          disclaimer: string
          menu_categories: {
            data: [
              {
                id: number
                attributes: {
                  title: string
                  image: ImageType
                  items: [
                    {
                      id: number
                      title: string
                      price: string
                      menu_item: [
                        {
                          id: number
                          title: string
                          price: string
                          description: string
                        },
                      ]
                    },
                  ]
                }
              },
            ]
          }
        }
      }
    }
  }
  id?: string
}) {
  const website = useWebsite()

  if (!data) return null

  const { copy, title, menu_categories, disclaimer } = data.menu.data.attributes

  return (
    <Container
      id={id}
      variant="full"
      css={[
        tw`py-12 bg-theme-secondary`,
        website === 'crockfords' && tw`bg-white`,
      ]}>
      <Container tw="py-12 px-2.5 border-t border-theme-accent">
        <Heading
          id={title.toLocaleLowerCase().replace(/ /g, '-')}
          as="h2"
          variant="h2"
          tw="text-theme-accent">
          {title}
        </Heading>
        <div className="mt-5 mb-7.5 text-lg font-normal">
          <RenderMarkdown>{copy}</RenderMarkdown>
        </div>
        {menu_categories && (
          <div tw="flex flex-col gap-y-6">
            {menu_categories.data.map(({ id, attributes }) => (
              <Disclosure
                css={[
                  tw`py-3.75 px-3 border-0 bg-white`,
                  website === 'crockfords' && tw`border border-theme-accent`,
                ]}
                as="div"
                key={id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button tw="flex justify-between w-full text-left bg-white">
                      <Heading
                        as="h3"
                        variant="h3"
                        css={[
                          tw`text-xl text-theme-accent`,
                          website === 'crockfords' && tw`font-newscycle!`,
                        ]}>
                        {attributes.title}
                      </Heading>
                      {open ? (
                        <ChevronUpIcon tw="w-6 h-6 text-theme-accent" />
                      ) : (
                        <ChevronDownIcon tw="w-6 h-6 text-theme-accent" />
                      )}
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform opacity-0"
                      enterTo="transform opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-100"
                      leaveTo="transform opacity-0">
                      <Disclosure.Panel>
                        {attributes.image && attributes.image.data && (
                          <div tw="my-3">
                            <Image
                              alt={
                                attributes.image.data.attributes.alternativeText
                              }
                              width={attributes.image.data.attributes.width}
                              height={attributes.image.data.attributes.height}
                              layout="responsive"
                              src={attributes.image.data.attributes.url}
                            />
                          </div>
                        )}

                        {attributes.items && attributes.items.length > 0 && (
                          <>
                            {attributes.items.map(
                              ({ id, menu_item, price, title }) => (
                                <div
                                  key={id}
                                  css={[
                                    tw`px-6.5 py-5 mb-1`,
                                    title && tw`border border-theme-accent`,
                                  ]}>
                                  {title && (
                                    <div tw="flex justify-between mb-5 font-light text-lg text-theme-accent uppercase">
                                      <Heading as="h4" variant="h4">
                                        {title}
                                      </Heading>
                                      <span
                                        css={[
                                          website === 'crockfords' &&
                                            tw`font-baskerville`,
                                        ]}>
                                        {price}
                                      </span>
                                    </div>
                                  )}
                                  <div tw="grid sm:grid-cols-2 gap-y-4 gap-x-12">
                                    {menu_item &&
                                      menu_item.length > 0 &&
                                      menu_item.map(
                                        ({ id, title, description, price }) => (
                                          <div key={id}>
                                            <div tw="flex justify-between font-light text-xl text-theme-accent">
                                              <Heading as="h5" variant="h5">
                                                {title}
                                              </Heading>
                                              {price && (
                                                <span
                                                  css={[
                                                    website === 'crockfords' &&
                                                      tw`font-baskerville`,
                                                  ]}>
                                                  {price}
                                                </span>
                                              )}
                                            </div>
                                            <p
                                              css={[
                                                tw`text-theme-secondary font-normal`,
                                                website === 'crockfords' &&
                                                  tw`font-newscycle text-theme-inverse`,
                                              ]}>
                                              {description}
                                            </p>
                                          </div>
                                        ),
                                      )}
                                  </div>
                                </div>
                              ),
                            )}
                          </>
                        )}
                        {disclaimer && (
                          <p
                            css={[
                              tw`px-6.5 text-xxs text-right text-theme-secondary`,
                              website === 'crockfords' &&
                                tw`font-newscycle text-theme-inverse`,
                            ]}>
                            {disclaimer}
                          </p>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </div>
        )}
      </Container>
    </Container>
  )
}

export { Menu }
