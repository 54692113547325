import tw, { css } from 'twin.macro'
import Image from 'next/image'

import { ContentBoxProps } from '@/types/content-boxes'
import { NextLink } from '@/atoms/next-link'

const styles = {
  card: [
    tw`relative border    
      grid grid-cols-1 grid-template-rows[172px 48px]   
    `,
    css`
      border-color: #d5c59f;
    `,
  ],
  heading: tw`col-span-full row-start-2 uppercase font-light flex items-center justify-center text-center z-50 relative
      text-xl h-12
      background-color[#9A8757]`,
  overlay: [
    tw`row-span-full col-span-full z-40 relative bg-opacity-50`,
    css`
      background-color: rgba(154, 135, 87, 0.6);
    `,
  ],
}

function ContentBox({ title, link, image }: ContentBoxProps) {
  return (
    <NextLink to={link}>
      <div css={styles.card}>
          <Image
            src={image.data.attributes.url}
            alt={image.data.attributes.alternativeText}
            layout="fill"
            objectFit="cover"
          />
        <div css={styles.overlay}></div>
        <div css={styles.heading}>{title}</div>
      </div>
    </NextLink>
  )
}

export { ContentBox }
