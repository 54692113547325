import config from "@/lib/config"

export async function getRecaptchaKey() {
  
  const { captchaSiteKey } = config

  return new Promise<string>((resolve, reject) => {
    const { grecaptcha } = window as any  
    let token;
  
    try {
      grecaptcha.ready(async () => {      
        token = await grecaptcha.execute(captchaSiteKey, {action: 'submit'})
        
        resolve(token)
      })
    } catch (error) {
      reject('Error getting token')
    }
  })
}