import Link from 'next/link'
import Image from 'next/image'
import tw from 'twin.macro'

import { PromotionItem as PromotionItemType } from '@/types/promotion-item'

import { useFormatDate } from '@/lib/format-date-time'

import { Icon } from '@/atoms/icon'

import { TermsAndConditions } from '@/molecules/ts-and-cs'

function PromotionItem({ as = 'article', attributes }: PromotionItemType) {
  const Component = as || 'article'

  const {
    call_to_action,
    heading,
    article_date,
    slug,
    summary,
    main_image,
    terms_and_conditions,
  } = attributes

  const formattedDate = useFormatDate(article_date!)

  return (
    <Component
      role="article"
      tw="relative w-full h-full min-h-[460px] bg-theme-secondary">
      <Link href={`/news/${slug}`}>
        <a tw="block h-full">
          <h3 className="sr-only">{heading}</h3>
          {main_image && (
            <div>
              <Image
                {...main_image}
                layout="fill"
                className="object-cover object-center"
                loading="lazy"
              />
            </div>
          )}

          <div tw="absolute left-0 right-0 bottom-5 flex flex-col items-center">
            {/* @ts-ignore */}
            <p className="sr-only">
              {article_date && (
                <time dateTime={article_date}>{formattedDate}</time>
              )}
            </p>

            <p className="sr-only">{summary}</p>

            <p tw="text-base font-thin border-2 border-white p-2 leading-5 text-center flex-shrink-0 px-10 cursor-pointer">
              {call_to_action ? call_to_action : 'Find Out More'}
            </p>
            <Icon
              variant="outline"
              icon="downArrow"
              tw="h-5.5 mt-2"
              aria-hidden
            />
            {terms_and_conditions && terms_and_conditions.summary && (
              <div
                tw="flex flex-col w-full text-white text-left pt-2 pb-1 px-10"
                onClick={(event) => {
                  event.stopPropagation()
                }}>
                <TermsAndConditions theme="default" {...terms_and_conditions} />
              </div>
            )}
          </div>
        </a>
      </Link>
    </Component>
  )
}

export { PromotionItem }
