import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

import tw from 'twin.macro'

import { Resorts } from '@/types/resorts-type'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { Icon } from '@/atoms/icon'
import { NextLink } from '@/atoms/next-link'

type Props = {
    data: Resorts
}

export function ResortsDropdown({ data }: Props) {
    const { strapline, heading, menu_items } = data

    return (
        <Container tw='my-10 text-theme-inverse text-center'>
            <Container tw='text-center py-16'>
                <Heading variant='h3' tw='text-theme-accent text-4.5xl'>
                    {/* @ts-ignore */}
                    {heading}
                </Heading>
                <p className='mt-8'>{strapline}</p>
                <Menu as='div' className='relative inline-block'>
                    {/* @ts-ignore */}
                    <Menu.Button tw='mt-8 w-[270px] max-w-full md:w-[350px] flex justify-between items-center rounded-full py-1 px-4 text-theme-muted font-light text-left border border-theme-accent'>
                        Select a location
                        <Icon icon='downArrow' variant='solid' tw='w-4 h-4 text-theme-accent' />
                    </Menu.Button>
                    {/* @ts-ignore */}
                    <Transition
                        as={Fragment}
                        enter='transition duration-100 ease-out'
                        enterFrom='transform scale-95 opacity-0'
                        enterTo='transform scale-100 opacity-100'
                        leave='transition duration-75 ease-out'
                        leaveFrom='transform scale-100 opacity-100'
                        leaveTo='transform scale-95 opacity-0'
                    >
                        {/* @ts-ignore */}
                        <Menu.Items tw='w-[calc(270px - 2.5rem)] md:w-[calc(350px - 2.5rem)] origin-center absolute mx-5 px-5 block divide-y divide-gray-400 text-sm text-left bg-white bg-opacity-90 z-40'>
                            {menu_items.map(({ title, link }, key) => {
                                return (
                                    <Menu.Item key={key}>
                                        <NextLink to={link} tw='block py-3 hover:text-theme-accent'>
                                            {title}
                                        </NextLink>
                                    </Menu.Item>
                            )})}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </Container>
        </Container>
    )
}
