import tw, { css } from 'twin.macro'

import { IPlatform } from '@/types/gaming-information-types'

import { useScrollIntoView } from '@/hooks/use-scroll-into-view'
import { useWebsite } from '@/hooks/use-website'

import { AHref } from '@/atoms/a-href'

import { TermsAndConditions } from '@/molecules/ts-and-cs'

function GamingInformationTab({
  title,
  copy,
  cta,
  terms_and_conditions,
}: IPlatform) {
  const website = useWebsite()
  const { scrollRef, handleScroll } = useScrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center',
  })

  return (
    <div
      ref={scrollRef}
      css={[
        tw`relative justify-center items-start text-center 
					font-light bg-theme-accent px-4 md:px-6 lg:px-8 row-span-full place-items-center 
					`,
        css`
          scroll-snap-align: center;
          display: grid;
          grid-template-rows: subgrid;
        `,
        website === 'genting-casinos' &&
          css`
            &:first-of-type::after {
              content: '';
              position: absolute;
              left: calc(50% - 20px);
              bottom: -20px;
              width: 0;
              height: 0;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 20px solid var(--border-accent);
              clear: both;
            }
          `,
        title === 'Casino Gaming',
      ]}
      /* @ts-ignore */
      onClick={(event) => handleScroll(event)}>
      <h3 tw="uppercase text-2xl md:text-3xl lg:text-4xl row-start-2 row-end-3">
        {title}
      </h3>
      <p className="my-3 row-start-3 row-end-4">{copy}</p>

      {cta ? (
        <>
          <AHref
            to={cta.link}
            theme="default"
            variant="inverse"
            tw="px-10 min-w-48 mt-6 px-6 inline-block mb-6 row-start-4 row-end-5">
            {cta.title}
          </AHref>
          {terms_and_conditions && terms_and_conditions.summary && (
            <TermsAndConditions
              clip={terms_and_conditions.clip}
              summary={terms_and_conditions.summary}
              link={terms_and_conditions.link}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export { GamingInformationTab }
