import { useState, useEffect, useRef } from 'react'
import tw, { css, theme } from 'twin.macro'

import Image from 'next/image'

import { Shop as ShopType, ShopsList as ShopsListType } from '@/types/shops'
import { SortOptions } from '@/types/sort-options'

import { Container } from '@/atoms/container'
import { Slider, SliderItem } from '@/atoms/slider'
import { Heading } from '@/atoms/headings'
import { Button } from '@/atoms/button'
import { SortIcon } from '@/atoms/sort-icon'
import { Popper } from '@/atoms/popper'
import { NextLink } from '@/atoms/next-link'

import { ShopsMobileSortPanel } from '@/organisms/shops-mobile-sort-panel'

import { useMediaQuery } from '@/hooks/use-media-query'

const styles = {
  container: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`pb-16 bg-theme-secondary`,
    isLargeScreen && itemsLength <= 4 && tw`xl:pb-0`,
  ],
  slider: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`pb-8 md:pb-12 grid grid-flow-col auto-cols-max gap-8 mt-12 md:mt-8`,
    isLargeScreen && itemsLength <= 4 && tw`!overflow-x-hidden h-[1000px]`,
    css`
      scroll-snap-type: none;
    `,
  ],
  sliderItem: [
    tw`flex-shrink-0 (width[calc(100vw - 40%)]  height[220px]) border height[172px] width[172px] flex justify-center items-center bg-white border-theme-secondary overflow-hidden
    `,
    css`
      width: calc(
        ${theme`screens.xl`} / 6 - ${theme`gap.5`} + (${theme`gap.5`} / 6)
      );
    `,
  ],
  shopItem: [
    tw`flex-shrink-0 border h-[172px]  w-full flex justify-center items-center bg-white border-theme-secondary overflow-hidden
    `,
  ],
  checkboxContainer: tw`divide-y divide-gray-200 border-b border-gray-200`,
  sort: tw`text-theme-inverse flex justify-center md:justify-end items-center mt-12 md:mt-0`,
  label: tw`hidden md:inline-block mr-5`,
  options: [
    tw`overflow-auto min-w-max`,
    css`
      max-height: 16rem;
    `,
  ],
  option: tw`w-full flex justify-start items-center text-base py-1 px-2`,
  optionRadio: tw`flex-shrink-0 w-3 h-3 border-theme-secondary`,
  optionLabel: tw`flex-shrink-0 mr-auto`,
  root: [
    tw`flex flex-col flex-1 text-theme-secondary`,
    css`
      background-color: #f9f9f9;
    `,
  ],
  title: tw`flex justify-center items-center text-theme-accent p-5`,
  close: tw`ml-auto p-4 pr-0`,
  content: tw`flex-1`,
  actions: tw`p-5 bg-white`,
}

const SORT_OPTIONS: SortOptions = {
  name_asc: {
    title: 'Name A-Z',
    function: (a: ShopType, b: ShopType) =>
      a.attributes.title.localeCompare(b.attributes.title),
  },
  name_desc: {
    title: 'Name Z-A',
    function: (a: ShopType, b: ShopType) =>
      b.attributes.title.localeCompare(a.attributes.title),
  },
}

function Shops({ data }: { data: ShopsListType }) {
  const { shops_lists, heading } = data
  const isMediumScreen = useMediaQuery(`(min-width: ${theme('screens.md')})`)
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`)
  const [viewAll, setViewAll] = useState(false)
  const [sortPanelOpen, setSortPanelOpen] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState<string>('name_asc')
  const [shops, setShops] = useState(shops_lists.data)
  const previousOption = useRef<string | null>(null)

  // set filter
  const setFilter = (option: string) => {
    setSortBy(option)

    if (isMediumScreen) {
      if (sortBy === 'name_desc')
        return setShops(
          shops.sort((a: ShopType, b: ShopType) =>
            a.attributes.title.localeCompare(b.attributes.title)
          )
        )
      if (sortBy === 'name_asc')
        return setShops(
          shops.sort((a: ShopType, b: ShopType) =>
            b.attributes.title.localeCompare(a.attributes.title)
          )
        )
    }

    previousOption.current = sortBy
    setSortBy(option)
  }

  // order shops a - z on initial load
  useEffect(() => {
    if (!shops) return

    setShops(
      shops.sort((a: ShopType, b: ShopType) =>
        a.attributes.title.localeCompare(b.attributes.title)
      )
    )
  }, [shops])

  return (
    <Container
      variant="full"
      as="section"
      css={styles.container({ isLargeScreen, itemsLength: shops.length })}>
      <div tw="text-theme-secondary bg-[#F0F0F0]">
        <Container as="section" tw="py-5 md:py-10 xl:py-20">
          <div tw="text-theme-inverse flex justify-center md:justify-end items-center mt-12 md:mt-0">
            <span css={styles.label}>Sort by</span>
            <div tw="hidden lg:block">
              {/* // desktop sort popper */}
              <Popper label={SORT_OPTIONS[sortBy].title} theme="accent">
                <div css={styles.options}>
                  {Object.keys(SORT_OPTIONS).map(
                    (option: string, optionIndex) => (
                      <label css={styles.option} key={optionIndex}>
                        <span css={styles.optionLabel}>
                          {SORT_OPTIONS[option].title}
                        </span>
                        <input
                          css={styles.optionRadio}
                          type="radio"
                          name="sort"
                          checked={sortBy === option}
                          onChange={() => setFilter(option)}
                        />
                      </label>
                    )
                  )}
                </div>
              </Popper>
            </div>

            {/* // mobile sort panel */}
            <div tw="lg:hidden">
              <Button
                theme="accent"
                variant="inverse"
                tw="max-w-[180px] flex-1 mx-2.5 px-5 inline-flex justify-center items-center"
                onClick={() => setSortPanelOpen(true)}>
                <SortIcon className="w-4 h-4 mr-2" />
                <span className="mr-auto">Sort by</span>
              </Button>
              <ShopsMobileSortPanel
                isOpen={sortPanelOpen}
                setOpen={(value: boolean) => setSortPanelOpen(value)}
                sortOptions={SORT_OPTIONS}
                sortBy={sortBy}
                setSortBy={setFilter}
                shops={shops}
                setShops={setShops}
                previousOption={previousOption}
              />
            </div>
          </div>
          <div className="mt-4 md:mt-10 flex flex-col md:flex-row justify-between">
            <Heading variant="h3" tw="hidden md:inline-block text-theme-accent">
              {heading}
            </Heading>
            <button
              tw="text-base md:text-xl text-center font-light tracking-wider font-medium"
              onClick={() => setViewAll(!viewAll)}>
              {viewAll ? 'View less' : 'View all'}
            </button>
          </div>
          {viewAll ? (
            // shops as blocks
            <div tw="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 auto-cols-max mt-12 md:mt-8 justify-items-center md:justify-items-start">
              {shops.map((shop: ShopType) => (
                <div css={styles.shopItem} key={shop.id}>
                  <div className="m-2 h-full w-full flex justify-center items-center">
                    <NextLink to={shop.attributes.link}>
                      <Image
                        tw="max-w-full"
                        src={shop.attributes.image.data.attributes.url}
                        alt={shop.attributes.title}
                        layout="fixed"
                        width={170}
                        height={shop.attributes.image.data.attributes.height}
                        objectFit="contain"
                      />
                    </NextLink>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // shops as slider
            <Slider
              css={styles.slider({
                isLargeScreen,
                itemsLength: shops.length,
              })}>
              {shops?.map((shop: ShopType) => (
                <SliderItem css={styles.sliderItem} key={shop.id}>
                  <div className="m-2 h-full w-full flex justify-center items-center ">
                    <NextLink to={shop.attributes.link}>
                      <Image
                        tw="max-w-full"
                        src={shop.attributes.image.data.attributes.url}
                        alt={shop.attributes.title}
                        layout="fixed"
                        width={170}
                        height={shop.attributes.image.data.attributes.height}
                        objectFit="contain"
                      />
                    </NextLink>
                  </div>
                </SliderItem>
              ))}
            </Slider>
          )}
        </Container>
      </div>
    </Container>
  )
}

export { Shops }
