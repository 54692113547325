import { ReactNode, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import tw from 'twin.macro'

type Props = {
    children: ReactNode
    isOpen: boolean
    id: string
    position?: 'top' | 'right' | 'bottom' | 'left'
    offset?: any
    transparent?: boolean
}

const enterFromLeaveTo = {
    top: '-translate-y-full',
    right: 'translate-x-full',
    bottom: 'translate-y-full',
    left: '-translate-x-full',
}

const enterToLeaveFrom = {
    top: 'translate-y-0',
    right: 'translate-x-0',
    bottom: 'translate-y-0',
    left: 'translate-x-0',
}

const placement = (position: string) => {
    if (position === 'top') {
        return 'inset-x-0 top-0'
    }

    if (position === 'right') {
        return 'inset-y-0 right-0'
    }

    if (position === 'bottom') {
        return 'inset-x-0 bottom-0'
    }

    return 'inset-y-0 left-0'
}

const sizing = (position: string) => {
    if (position === 'top' || position === 'bottom') {
        return 'w-screen h-screen max-h-96'
    }

    return 'w-screen h-screen max-w-md'
}

function SlideOver({ children, isOpen = false, id, position = 'left', offset, transparent = false }: Props) {
    const containerRef = useRef(null)

    return (
        // @ts-ignore
        <Transition.Root show={isOpen} as={Fragment}>
            {/* @ts-ignore */}
            <Dialog
                as='div'
                static
                className='fixed z-50 inset-0 overflow-hidden'
                css={offset}
                open={isOpen}
                id={id}
                onClose={() => {}}
                initialFocus={containerRef}                
            >
                <div ref={containerRef} className='absolute inset-0 overflow-hidden'>
                    {/* @ts-ignore */}
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div css={offset} className={`fixed max-w-full flex ${placement(position)}`}>
                        <Transition.Child
                            as={Fragment}
                            enter='transform transition ease-in-out duration-500 sm:duration-700'
                            enterFrom={enterFromLeaveTo[position]}
                            enterTo={enterToLeaveFrom[position]}
                            leave='transform transition ease-in-out duration-500 sm:duration-700'
                            leaveFrom={enterToLeaveFrom[position]}
                            leaveTo={enterFromLeaveTo[position]}
                        >
                            <div className={`relative ${sizing(position)}`}>
                                <div css={[
                                    tw`w-full h-full flex flex-col bg-[#F5F5F5] overflow-y-scroll`,
                                    transparent && tw`opacity-80`
                                    ]}
                                >{children}</div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export { SlideOver }
