import { useState, useEffect } from 'react'
import tw, { css, theme } from 'twin.macro'

import { NewsItem as NewsItemTypes } from '@/types/news'

import { useCollection } from '@/hooks/use-cms-data'
import { useMediaQuery } from '@/hooks/use-media-query'

import { AHref } from '@/atoms/a-href'
import { Heading } from '@/atoms/headings'

import { NewsItem } from '@/molecules/news-item'
import { PromotionItem } from '@/molecules/promotion-item'
import { useWebsite } from '@/hooks/use-website'

const styles = {
  grid: [
    tw`grid grid-cols-1 auto-rows-[minmax(380px, auto)]`,
    tw`sm:(grid-cols-2 auto-rows-[minmax(400px, 1fr)])`,
    tw`md:grid-cols-3`,
    tw`lg:grid-cols-4`,
    css`
      background-color: #fff;
    `,
  ],
  item: (promotion: boolean) => [
    tw`w-full bg-hexagon-pattern`,
    promotion &&
      css`
        grid-row: span 2;
      `,
  ],
  footer: tw`text-center py-16`,
  readMore: [
    tw`block ml-auto mr-auto text-lg font-thin border-2 border-white p-2 leading-7.5`,
    tw`lg:(text-base leading-5)`,
    css`
      max-width: 18.4375rem;
      @media (min-width: 1024px) {
        max-width: 13.5625rem;
      }
    `,
  ],
}

const determineVariant = (
  smallScreen: boolean,
  mediumScreen: boolean,
  largeScreen: boolean,
  index: number,
  promotionLength: number
) => {
  if (largeScreen) {
    if (promotionLength === 2) {
      return index === 0 || index === 2 ? 'light' : 'dark'
    } else if (promotionLength === 1) {
      return index === 0 || index === 2 || index === 6 ? 'light' : 'dark'
    }

    return index === 0 || index === 2 || index === 5 || index === 7
      ? 'light'
      : 'dark'
  }

  if (mediumScreen) {
    if (promotionLength === 1) {
      return index === 0 || index === 2 ? 'light' : 'dark'
    }

    return index === 0 || index === 2 || index === 4 ? 'light' : 'dark'
  }

  if (smallScreen) {
    return index === 0 || index === 3 ? 'light' : 'dark'
  }

  return index % 2 === 0 ? 'light' : 'dark'
}

function NewsGrid({ data }: { data: any }) {
  const website = useWebsite()

  const { data: newsData } = useCollection('news-articles', {
    filters: {
      applications: {
        slug: website,
      },
    },
    pagination: {
      pageSize: 20,
      page: 1,
    },
    sort: 'article_date:DESC',
  })

  const { call_to_action, heading } = data

  const [news, setNews] = useState<NewsItemTypes[] | undefined>(() => {
    if (!newsData) return []

    return newsData.data
      .filter((item: NewsItemTypes) => item.attributes.type === 'News')
      .slice(0, 4)
  })
  const [promotionLength, setPromotionLength] = useState(0)

  const isSmallScreen = useMediaQuery(`(min-width: ${theme('screens.sm')})`)
  const isMediumScreen = useMediaQuery(`(min-width: ${theme('screens.md')})`)
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`)

  useEffect(() => {
    if (!newsData) return

    let newNews = []

    if (isLargeScreen || isMediumScreen) {
      // We want to show one potential promotion on medium screen and two
      // on a large screen
      const promotions = newsData.data
        .filter((item: NewsItemTypes) => item.attributes.type === 'Promotion')
        .slice(0, isLargeScreen ? 2 : 1)

      // Keep track of length, it's important
      setPromotionLength(promotions.length)

      // Grab non-promotion - grid accounts for six normal items for medium
      // screen and eight normal items for large screens BUT we want to
      // subtract the number of items the promotions would normally take up
      // which is where the number of promotions come into play - subtract
      // total possible normal items minus number of promotions we have
      // multiplied by the number of rows a promotion takes up - two
      newNews = newsData.data
        .filter((item: NewsItemTypes) => item.attributes.type === 'News')
        .slice(0, (isLargeScreen ? 8 : 6) - promotions.length * 2)

      // Strategically place promotions in the list
      if (promotions.length === 2) {
        newNews.splice(1, 0, promotions[0])
        newNews.splice(3, 0, promotions[1])
      } else if (promotions.length === 1) {
        newNews.splice(1, 0, promotions[0])
      }
    } else {
      newNews = newsData.data
        .filter((item: NewsItemTypes) => item.attributes.type === 'News')
        .slice(0, 4)
    }

    setNews(newNews)
  }, [newsData, isSmallScreen, isMediumScreen, isLargeScreen])

  return (
    <div tw="bg-[#4f5359] sm:(grid grid-template-rows[190px auto 190px] items-center)">
      <Heading variant="h2" tw="py-9 text-center">
        {heading}
      </Heading>

      {news && (
        <div css={styles.grid}>
          {news.map((item: NewsItemTypes, index: number) => (
            <div
              css={styles.item(item.attributes.type === 'Promotion')}
              key={item.id}>
              {item.attributes.type === 'News' ? (
                <NewsItem
                  key={item.id}
                  variant={determineVariant(
                    isSmallScreen,
                    isMediumScreen,
                    isLargeScreen,
                    index,
                    promotionLength
                  )}
                  attributes={item.attributes}
                />
              ) : (
                <PromotionItem key={item.id} attributes={item} />
              )}
            </div>
          ))}
        </div>
      )}

      <div css={styles.footer}>
        <AHref
          to="/news"
          theme="default"
          variant="inverse"
          tw="px-10 font-light">
          {call_to_action}
        </AHref>
      </div>
    </div>
  )
}

export { NewsGrid }
