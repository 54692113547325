// @ts-nocheck
import { useEffect, useRef, useState } from 'react'
import tw, { theme } from 'twin.macro'
import { formatDistanceStrict } from 'date-fns'
import { useScrollIntoView } from '@/hooks/use-scroll-into-view'
import { useMediaQuery } from '@/hooks/use-media-query'

const useVideoGallery = ({ strapline, heading, videos }) => {
	const [mainVideo, setMainVideo] = useState(videos[0])
	const [playVideo, setPlayVideo] = useState(false)
	const [showVideo, setShowVideo] = useState(false)
	

	const videoRef = useRef<HTMLVideoElement>(null)
	const { scrollRef, handleScroll } = useScrollIntoView()
	const isMediumScreen = useMediaQuery(`(min-width: ${theme('screens.md')})`)
	let isFullScreen = false

	// stop video
	const stopVideo = () => {
		setShowVideo(false)
		exitFullscreen()
	}

	const startVideo = () => {
		setPlayVideo(true)
		videoRef.current?.play()
		videoRef.current?.addEventListener('ended', () => {
			setPlayVideo(false)
		})
	}

	// start video
	const startVideoMobile = () => {
		setShowVideo(true)
		setTimeout(function () {
			;['ended', 'webkitendfullscreen'].forEach((evt) =>
				videoRef.current?.addEventListener(evt, stopVideo, false)
			)

			// launch full screen
			videoRef.current?.play()
			launchFullScreen(videoRef.current)
		}, 1)
	}

	// if desktop let video player handle play/stop
	const handleVideo = (index: number, e) => {
		setMainVideo(videos[index])
		if (!isMediumScreen) return startVideoMobile()
		handleScroll(e)
		setTimeout(() => {
			setPlayVideo(true)
			videoRef.current?.play()
		}, 1)
	}

	// stop video on fullscreenchange
	const handleFullScreenChange = () => {
		isFullScreen = !isFullScreen

		if (!isFullScreen) {
			stopVideo()
		}
	}

	useEffect(() => {
		videoRef.current?.addEventListener('ended', () => {
			setPlayVideo(false)
		})
		videoRef.current?.load()
		setPlayVideo(false)
	}, [mainVideo])

	useEffect(() => {
		setMainVideo(videos[0])
	}, [videos])
	

	// stop video event support for different browsers
	useEffect(() => {
		;['fullscreenchange', 'webkitfullscreenchange', 'MSFullscreenChange'].forEach((evt) =>
			document.addEventListener(evt, handleFullScreenChange, false)
		)
	}, [])
	

	const launchFullScreen = (element) => {
		if (element.requestFullscreen) {
			return element.requestFullscreen()
		}

		// OS X?
		if (element.webkitRequestFullscreen) {
			return element.webkitRequestFullscreen()
		}

		// iOS
		if (element.webkitEnterFullscreen) {
			return element.webkitEnterFullscreen()
		}

		// IE11
		if (element.msRequestFullscreen) {
			return element.msRequestFullscreen()
		}
	}

	const exitFullscreen = () => {
		if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen()
		}
	}

	const getTimeSince = (date: string) => {
		return formatDistanceStrict(new Date(), new Date(date)) + ' ago'
	}

	return {
		launchFullScreen,
		exitFullscreen,
		getTimeSince,
		startVideo,
		handleVideo,
		videoRef,
		mainVideo,
		playVideo,
		showVideo,
		strapline,
		heading,
		videos,
		scrollRef,
		isMediumScreen,
	}
}

export default useVideoGallery
