import Image from 'next/image'
import tw, { css } from 'twin.macro'

import { Hero as HeroType } from '@/types/hero'

import { useMediaQuery } from '@/hooks/use-media-query'
import { useWebsite } from '@/hooks/use-website'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'

import { TermsAndConditions } from '@/molecules/ts-and-cs'

function ContentHeroImage({ data }: { data: HeroType }) {
  const website = useWebsite()

  const {
    strapline,
    heading,
    cta_text,
    background_image,
    foreground_image,
    terms_and_conditions,
  } = data

  return (
    <header
      data-testid="hero-header"
      className="grid auto-cols-fr grid-rows-1 bg-theme-secondary relative">
      <Container
        variant="full"
        css={[
          terms_and_conditions ? tw`min-h-[20rem]` : tw`min-h-[12rem]`,
          tw`col-span-full row-span-full relative z-20 lg:min-h-[20rem] py-4`,
        ]}>
        <div className="h-full max-w-screen-xl mx-5 md:mx-10 xl:mx-auto flex justify-between items-center">
          <div className="flex flex-col justify-center items-start">
            {strapline && <p>{strapline}</p>}
            <Heading
              variant="h2"
              css={[
                tw`max-w-xs sm:max-w-sm md:max-w-md xl:max-w-xl mt-3 text-4xl lg:(mt-4 text-4.5xl)`,
                website === 'genting-casinos'
                  ? tw`text-white`
                  : tw`text-theme-accent`,
              ]}>
              {heading}
            </Heading>
            {cta_text && (
              <p tw="inline-block md:sr-only mt-2 py-1 px-3 uppercase font-light bg-[#9A8757]">
                {cta_text}
              </p>
            )}
          </div>
          {foreground_image && foreground_image.data && (
            <div
              css={[
                tw`hidden w-1/2 md:flex justify-end h-48 lg:h-80`,
                css`
                  > span {
                    text-align: right;
                    height: 100%;
                    position: unset !important;
                  }
                `,
              ]}>
              <Image
                data-testid="foreground-image"
                src={foreground_image.data.attributes.url}
                alt={foreground_image.data.attributes.alternativeText}
                layout="fill"
                objectFit="contain"
                css={[
                  tw`!relative !w-auto`,
                ]}
              />
            </div>
          )}
        </div>
      </Container>
      {background_image && background_image.data && (
        <div className=" col-span-full row-span-full z-10 relative ">
          <Image
            data-testid="background-image"
            src={background_image.data.attributes.url}
            alt={background_image.data.attributes.alternativeText}
            layout="fill"
            objectFit="cover"
            className={`object-${
              background_image.data.attributes?.position
                ? background_image.data.attributes?.position
                : 'center'
            }`}
          />
        </div>
      )}
      {terms_and_conditions && terms_and_conditions.summary && (
        <div tw="absolute bottom-0 left-0 p-4 w-full px-6 md:px-10 xl:px-0 bg-[rgba(35, 40, 48, .5)] z-30">
          <div tw="max-w-screen-xl mx-auto">
            <TermsAndConditions theme="default" {...terms_and_conditions} />
          </div>
        </div>
      )}
    </header>
  )
}

export { ContentHeroImage }
