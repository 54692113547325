import tw, { css } from 'twin.macro'

import {
  Link as LinkTypes,
  LinkGroupWithHeadingCopy as LinkGroupTypes,
} from '@/types/link-group-with-heading-copy'

import { AHref } from '@/atoms/a-href'
import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'

function LinkGroupHeadingCopy({ data }: { data: LinkGroupTypes }) {
  const { link_group_heading, copy, links, link_cta } = data

  return (
    <Container
      variant="full"
      as="section"
      tw="py-16 bg-theme-accent bg-diamonds-pattern">
      <Container>
        <Heading as="h3" variant="h2" tw="mb-2 text-white leading-none">
          {link_group_heading.heading}
        </Heading>
        <p className="leading-6 uppercase font-light text-xl tracking-wide text-white">
          {link_group_heading.strapline}
        </p>

        {links && (
          <ul className="px-2 mt-18 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
            {links.map(({ id, title, link, icon }: LinkTypes) => (
              <li key={id} tw="w-full flex justify-center">
                <AHref
                  to={link}
                  theme="default"
                  variant="inverse"
                  tw="w-full sm:w-[260px] border-l-0 relative pt-[16px] flex items-center border-0 focus:(outline-none ring-offset-8 ring-offset-[#7b070b])">
                  <div
                    tw="absolute left-0 top-0 p-2 -ml-2 flex items-center justify-center border border-white rounded-full w-[88px] h-[88px] bg-theme-accent"
                    dangerouslySetInnerHTML={{
                      __html: icon.data.attributes.svg,
                    }}
                  />
                  <span tw="w-full pl-[76px] pr-4 py-4 border border-white">
                    {title}
                  </span>
                </AHref>
              </li>
            ))}
          </ul>
        )}

        {copy ? (
          <p className="mt-14 text-center text-xl font-light">{copy}</p>
        ) : (
          ''
        )}

        {link_cta?.link ? (
          <p className="mt-8 flex place-content-center">
            <AHref
              to={link_cta.link}
              theme="default"
              variant="inverse"
              tw="px-9">
              {link_cta.title}
            </AHref>
          </p>
        ) : (
          ''
        )}
      </Container>
    </Container>
  )
}

export { LinkGroupHeadingCopy }
