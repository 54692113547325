import { ReactNode, useState } from 'react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import tw, { css } from 'twin.macro'

import { Button } from '@/atoms/button'
import { Icon } from '@/atoms/icon'

type Theme = 'default' | 'accent';
type Variant = 'rounded' | 'square';

const styles = {
  button: (theme: Theme, variant: Variant, open: boolean) => [
    tw`p-2 pl-4 pr-4 min-w-[200px] flex justify-between items-center cursor-pointer relative z-20 border-2`,
    variant === 'rounded' && tw`rounded-3xl`,
    theme === 'default' && tw`border-white text-white`,
    theme === 'accent' && tw`border-theme-accent text-theme-accent`,
    open && theme === 'default' && tw`bg-white text-theme-accent`,
    open && theme === 'accent' && tw`bg-theme-accent text-white`,
  ],
  panel: [
    tw`absolute z-30 text-theme-secondary divide-y divide-gray-200 bg-white focus:outline-none`,
    css`
      min-width: 12.5rem;

      &[data-popper-placement^='top'] > [data-popper-arrow] {
        bottom: -0.25rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &[data-popper-placement^='bottom'] > [data-popper-arrow] {
        top: -0.25rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &[data-popper-placement^='left'] > [data-popper-arrow] {
        right: -0.25rem;
        top: 50%;
        transform: translateY(-50%);
      }

      &[data-popper-placement^='right'] > [data-popper-arrow] {
        left: -0.25rem;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
  ],
  arrow: css`
    &, &::before {
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      background: inherit;
    }

    & {
      visibility: hidden;
    }

    &::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  `,
  content: [
    tw`overflow-auto p-2`,
    css`max-height: 16rem;`,
  ],
};

type Props = {
  label: string,
  icon?: ReactNode,
  showButton?: boolean,
  buttonLabel?: string,
  closeOnClick?: boolean,
  children: ReactNode,
  theme?: Theme,
  variant?: Variant,
};

function Popper({ label, icon, showButton = false, buttonLabel = 'Apply', closeOnClick = false, theme = 'default', variant = 'square', children }: Props) {
  const [ buttonRef, setButtonRef ] = useState<HTMLButtonElement|null>(null);
  const [ panelRef, setPanelRef ] = useState<HTMLDivElement|null>(null);

  const { styles: popperStyles, attributes } = usePopper(buttonRef, panelRef, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  return (
    <Popover className="inline-block relative">
      {({ open }) => (
        <>
          {/* @ts-ignore */}
          <Popover.Overlay
            data-testid="popper-overlay"
            css={[
              tw`bg-black`,
              open ? tw`opacity-30 fixed inset-0 z-10` : tw`opacity-0`
            ]}
          />
          <Popover.Button
            css={styles.button(theme, variant, open)}
            ref={setButtonRef}
          >
            <span tw="inline-flex justify-start items-center whitespace-nowrap mr-4">
              {icon ? <span tw="mr-2">{icon}</span> : null}
              {label}
            </span>
            <Icon icon="downArrow" variant="solid" tw="w-2.5 h-2.5 ml-2" />
          </Popover.Button>

          {open ? (
            <Popover.Panel
              static
              as="div"
              ref={setPanelRef}
              css={styles.panel}
              style={popperStyles.popper}
              data-testid="popper-panel"
              {...attributes.popper}
            >
              <div css={styles.arrow} data-popper-arrow />

              <div css={styles.content} 
                onClick={() => {
                  closeOnClick ? buttonRef?.click() : null
                }}>
                {children}
              </div>

              {showButton ? (
                <div className="p-2">
                  <Button theme="accent" variant="contained" tw="w-full" onClick={() => buttonRef?.click()}>
                    {buttonLabel}
                  </Button>
                </div>
              ) : null}
            </Popover.Panel>
          ) : null}
        </>
      )}
    </Popover>
  )
}

export { Popper };
