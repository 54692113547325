import tw, { css } from 'twin.macro'

import { TJackpots } from '@/types/homepage'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { JackpotComposedImage } from '@/molecules/jp-composed-img'
import { useJackpots } from '@/hooks/use-jackpots-data'
import { useWebsite } from '@/hooks/use-website'
import { NextLink } from '@/atoms/next-link'

export function Jackpots({ data }: { data: any }) {
  const { heading, heading_copy }: TJackpots = data.title_and_strapline
  const { mega_img, major_img, mini_img, bg_border_img, cta } = data
  const website = useWebsite()

  const {
    data: { attributes: major_img_main },
  } = major_img
  const {
    data: { attributes: mega_img_main },
  } = mega_img
  const {
    data: { attributes: mini_img_main },
  } = mini_img
  const {
    data: { attributes: bg_border_img_main },
  } = bg_border_img

  const {
    data: { values, friendlyLastModified },
  } = useJackpots()

  return (
    <div css={[
      tw`bg-diamonds-pattern py-20 text-theme-primary`,
      website === 'genting-casinos' && tw `bg-theme-accent bg-theme-gold`,
      website === 'the-palm-beach' && css`background-color: var(--color-secondary); background-blend-mode: soft-light;`
    ]}>
      <Container>
        <Heading css={[
          tw`text-center pb-10`,
          website === 'genting-casinos' && tw`lg:text-6xl`,
          website === 'the-palm-beach' && tw`lg:text-left text-theme-gold`,
        ]}>Jackpots</Heading>

        <div className="flex flex-col lg:grid lg:grid-cols-12 lg:mt-10">
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left lg:col-span-3 lg:col-start-1">
            <Heading css={[
              tw`self-center uppercase text-3xl font-light tracking-widest leading-14`,
              tw`lg:self-auto lg:(text-2xl)`,
              website === 'the-palm-beach' && tw`font-sans!`,
            ]}>{heading}</Heading>

            <hr css={[
              tw`my-10 w-10/12`,
              tw`lg:(my-5 w-64)`,
            ]} aria-hidden />

            <p css={[
              tw`text-white font-light w-10/12 text-lg text-center`,
              tw`lg:(w-auto text-left mr-5)`,
              tw`xl:mr-20`,
              website === 'the-palm-beach' && tw`text-base`,
            ]}>{heading_copy}</p>
            {cta && (
              <NextLink
                to={cta?.link}
                css={[
                  tw`p-2 mt-10 block text-center border 
                  focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500 cursor-pointer |  min-w-[200px]`,
                  website === 'the-palm-beach' && tw`uppercase`,
                ]}
              >
                {cta?.title}
              </NextLink>
            )}
          </div>
          <div tw="grid lg:(mt-3) xl:(mt-3 col-span-8 col-start-5)">
            <div tw="flex flex-col mt-18 gap-8 lg:(mt-3 grid grid-flow-col) xl:(mt-3 col-span-8 col-start-5)">
              {values?.Mega && (
                <JackpotComposedImage
                  tw="lg:row-start-1"
                  type="Mega"
                  borderImg={bg_border_img_main}
                  jpImg={mega_img_main}
                  jpAmount={values.Mega}
                />
              )}
              {values?.Major && (
                <JackpotComposedImage
                  tw="lg:row-start-1"
                  type="Major"
                  borderImg={bg_border_img_main}
                  jpImg={major_img_main}
                  jpAmount={values.Major}
                />
              )}
              {values?.Mini && (
                <JackpotComposedImage
                  tw="lg:row-start-2"
                  type="Mini"
                  borderImg={bg_border_img_main}
                  jpImg={mini_img_main}
                  jpAmount={values.Mini}
                />
              )}
            </div>
            <div tw="flex flex-col mt-10 gap-8 text-center lg:(mt-3 grid grid-flow-col) xl:(mt-3 col-span-8 col-start-5) text-xs">
              Correct as of: {friendlyLastModified}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
