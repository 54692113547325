import tw, { css } from 'twin.macro'

import { NextLink } from '@/atoms/next-link'

function ButtonsCTA({ data }) {
  if (data) {
    return (
      <div tw="flex lg:hidden border-b border-t border-white">
        {data.buttons.map(({ id, link, title }, index) => (
          <NextLink
            key={id}
            to={link}
            css={[
              tw`w-full py-2 flex justify-center items-center uppercase bg-theme-accent`,
              index % 2 !== 0 && tw`text-theme-accent bg-white`,
            ]}>
            <span tw="pr-2">{title}</span>
          </NextLink>
        ))}
      </div>
    )
  }
}

export { ButtonsCTA }
