import { useState, useEffect, useRef, useLayoutEffect, useImperativeHandle, forwardRef } from 'react'
import tw, { css } from 'twin.macro'
import Headroom from 'headroom.js'

type Props = {
	data: {
		hash: string
		text: string
	}[]
}

const SlidingNav = forwardRef (({ data }: Props, ref) => {
	const [active, setActive] = useState('')
	const [unpinned, setUnpinned] = useState(false)
	const navRef = useRef<HTMLDivElement>(null)
	const sliderRef = useRef<HTMLDivElement>(null)

	let headroom: any = null

	useLayoutEffect(() => {
		if (sliderRef.current) sliderRef.current.scrollTop = 4
	}, [])

	useEffect(() => {
		setActive(data[0].hash)
	}, [])

	useEffect(() => {
		if (navRef.current && !headroom) {
			headroom = new Headroom(navRef.current, {
				onTop: () => setUnpinned(false),
				onNotTop: () => setUnpinned(true),
				offset: navRef.current.getBoundingClientRect().top,
			})
			headroom.init()
		}
	}, [navRef.current])

	useImperativeHandle(ref, () => ({
		setActiveScroll: (hash: string) => {
			setActive('#' + hash);
		},
	}))

	return (
		<div ref={navRef} tw="sticky top-[0px] uppercase text-center text-2xl font-light z-30">
			<div
				ref={sliderRef}
				css={[
					tw`block animate-bounceIn overflow-x-auto whitespace-nowrap 
					overflow-y-hidden md:(inline-block overflow-visible) 
					bg-black bg-opacity-50`,
					css`
						-ms-overflow-style: none;
						&::-webkit-scrollbar {
							width: 0px;
							height: 0px;
							background: transparent;
						}
					`,
					!unpinned && tw`bg-transparent`,
				]}>
				{data.map((link) => (
					<a	
						data-id={link.hash}
						key={link.hash}
						href={link.hash}
						css={[
							tw`inline-block relative h-10 py-10 px-5 border-b-4 border-theme-secondary`,
							active === link.hash && tw`border-white`
						]}>
						{link.text}
					</a>
				))}
			</div>
		</div>
	)
})

export { SlidingNav }
