import Image from 'next/image'
import tw, { theme } from 'twin.macro'

import { AHref } from '@/atoms/a-href'
import { Container } from '@/atoms/container'
import { CoverImage } from '@/atoms/cover-image'

import { useMediaQuery } from '@/hooks/use-media-query'
import { PromotionBanner as PromotionBannerType } from '@/types/promotional-banner'

function PromotionBanner({ data }: { data: PromotionBannerType }) {
  const isMediumScreen = useMediaQuery(`(min-width: ${theme('screens.md')})`)
  const { background, background_mobile, foreground, link, title } = data

  return (
    <Container
      variant="full"
      as="section"
      tw="grid grid-template-rows[2em auto 2em]">
      <div className="col-span-full row-span-full z-10 relative">
        {background.data.attributes.url &&
          background_mobile.data.attributes.url && (
            <CoverImage
              src={
                isMediumScreen
                  ? background.data.attributes.url
                  : background_mobile.data.attributes.url
              }
              alt={
                isMediumScreen
                  ? background?.data.attributes.alternativeText
                  : background_mobile?.data.attributes.alternativeText
              }
              position={'center'}
            />
          )}
      </div>
      <Container tw="w-auto xl:w-full col-span-full row-start-2 z-20 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div tw="col-span-full md:col-start-2 z-20 flex flex-col justify-between md:justify-center md:w-[815px] md:max-w-[45vw]">
          <Image
            width={566}
            height={245}
            layout="responsive"
            alt={foreground?.data.attributes.alternativeText}
            src={foreground.data.attributes.url}
          />
          <AHref
            to={link}
            theme="default"
            variant="contained"
            tw="text-theme-accent self-center px-16 mt-8">
            {title}
          </AHref>
        </div>
      </Container>
    </Container>
  )
}

export { PromotionBanner }
