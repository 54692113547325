import tw from 'twin.macro'
import Image from 'next/image'

import { VideoPlaceholderTypes } from '@/types/video-gallery-placeholder'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { VideoGalleryPlaceholder } from '@/atoms/video-gallery-placeholder'
import PlayIcon from '@/atoms/play-icon'

import { Video } from '@/molecules/video'

import useVideoGallery from '@/hooks/use-video-gallery'

function VideoGallery({
  data,
}: {
  data: {
    strapline: string
    heading: string
    videos: string
  }
}) {
  const {
    getTimeSince,
    startVideo,
    handleVideo,
    videoRef,
    mainVideo,
    playVideo,
    showVideo,
    strapline,
    heading,
    videos,
    scrollRef,
    isMediumScreen,
  } = useVideoGallery(data)

  return (
    <Container
      variant="full"
      tw="pb-10 pt-18 bg-theme-accent bg-diamonds-pattern">
      <Container>
        <section tw="mb-12 mt-5" ref={scrollRef}>
          <p tw="leading-6 tracking-wide uppercase">{strapline}</p>
          <Heading as="h3" variant="h2" tw="mt-2 text-white">
            {heading}
          </Heading>
        </section>
        <section>
          {(isMediumScreen || showVideo) && (
            <>
              <div tw="absolute z-[-1] md:relative inline-block w-full xl:w-auto md:(border-[1.5px] border-solid border-white z-auto)">
                <Video ref={videoRef} data={mainVideo} controls />
                <div tw="hidden md:block">
                  {mainVideo.placeholder && !playVideo && (
                    <Image
					            alt=""
                      src={mainVideo.placeholder.data.attributes.url}
                      layout="fill"
                      objectFit="cover"
                    />
                  )}
                  {!playVideo && (
                    <PlayIcon
                      tw="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2  w-28 md:w-48 cursor-pointer z-20"
                      onClick={startVideo}
                    />
                  )}
                </div>
              </div>

              {isMediumScreen && (
                <div>
                  <Heading
                    as="h4"
                    variant="h3"
                    tw="mt-5 text-white capitalize text-3xl">
                    {mainVideo.title}
                  </Heading>
                  <p tw="text-white opacity-50 text-sm leading-6 mt-2">
                    {getTimeSince(
                      mainVideo.formats[0].video_format.data.attributes
                        .createdAt
                    )}
                  </p>
                  <hr tw="opacity-50 my-12" />
                </div>
              )}
            </>
          )}
        </section>
        <section tw="grid sm:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-5 md:gap-y-10">
          {videos.map((video: VideoPlaceholderTypes, index: number) => {
            return (
              <VideoGalleryPlaceholder
                key={video.title}
                video={video}
                index={index}
                handleVideo={handleVideo}
                getTimeSince={getTimeSince}
              />
            )
          })}
        </section>
      </Container>
    </Container>
  )
}

export { VideoGallery }
