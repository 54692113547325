import tw from 'twin.macro'
import Image from 'next/image'

import { TeamDetails as TeamDetailsTypes } from '@/types/team'

import { RenderMarkdown } from '@/lib/render-markdown'

import { useWebsite } from '@/hooks/use-website'

import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { Typography } from '@/atoms/typography'

function TeamDetails({ data }: { data: TeamDetailsTypes }) {
  const { team_member } = data

  return (
    <Container tw="grid grid-cols-1 gap-16 md:(grid-cols-2 gap-20) py-28 text-theme-inverse">
      <div>
        <Heading as="h1" tw="text-theme-accent mb-2">
          {team_member.data.attributes.name}
        </Heading>
        <p className="leading-6 tracking-wide mb-10">
          {team_member.data.attributes.role}
        </p>
        <Typography>
          <RenderMarkdown>
            {team_member.data.attributes.description}
          </RenderMarkdown>
        </Typography>
      </div>
      <div tw="relative h-[400px]">
        <Image
          src={team_member.data.attributes.image.data.attributes.url}
          alt={
            team_member.data.attributes.image.data.attributes.alternativeText
          }
          layout="fill"
          objectFit="contain"
          className="w-full object-top"
        />
      </div>
    </Container>
  )
}

export { TeamDetails }
