import tw from 'twin.macro'
import Image from 'next/image'

import { TGame } from '@/types/gaming-information-types'

import { usePathPrefix } from '@/hooks/use-path-prefix'

import { CoverImage } from '@/atoms/cover-image'
import { NextLink } from '@/atoms/next-link'

export function GameInfoCard({
  game: {
    attributes: { title, image, url, foreground },
  },
}: {
  game: TGame
}) {
  const pathPrefix = usePathPrefix()
  return (
    <>
      <NextLink to={`${pathPrefix}/${url}`}>
        <div tw="grid grid-template-rows[95px 1fr 60px 40px] grid-cols-1 w-full h-full border border-[#d5c59f]">
          <div tw="row-span-full col-span-full z-20 bg-[#9a8757] bg-opacity-50 hover:bg-opacity-80">
            <div tw="flex justify-center items-center transition duration-700 opacity-0 hover:opacity-100 w-full h-full z-20 focus:(outline-none ring-2 ring-red-500 border-red-500)">
              <p className="text-white text-center tracking-tight text-base py-2 font-light border-white border w-2/3 cursor-pointer">
                Learn More
              </p>
            </div>
          </div>
          <div className="relative row-start-1 row-span-3 col-start-1 w-full h-full z-10">
            {image && (
              <CoverImage
                tw="w-full h-full col-span-full row-span-full z-20 relative"
                src={image.data.attributes.url}
                alt={image.data.attributes.alternativeText || `${title} image`}
                sizes="320px"
              />
            )}
            {foreground.data && (
              <Image
                tw="w-full h-full col-span-full row-span-full z-20 relative"
                src={foreground.data.attributes.url}
                alt={
                  foreground.data.attributes.alternativeText || `${title} image`
                }
                layout="fill"
                objectFit="contain"
              />
            )}
          </div>
          <div className="col-start-1 mt-2 text-center row-start-4 uppercase">
            {title}
          </div>
        </div>
      </NextLink>
    </>
  )
}
