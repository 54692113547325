import Link from 'next/link'
import tw, { css } from 'twin.macro'

import { NewsItem as NewsItemType } from '@/types/news'

import { useWebsite } from '@/hooks/use-website'

import { CoverImage } from '@/atoms/cover-image'
import { useFormatDate } from '@/lib/format-date-time'

import { Icon } from '@/atoms/icon'

import { TermsAndConditions } from '@/molecules/ts-and-cs'

const styles = {
  root: (website: string, variant: string) => [
    tw`relative h-full hover:cursor-pointer`,
    variant === 'light' &&
      css`
        background-color: rgba(26, 27, 31, 0.68);
      `,
    variant === 'dark' &&
      css`
        background-color: rgba(0, 2, 10, 0.84);
      `,
    website === 'the-palm-beach' && [
      tw`text-theme-inverse`,
      css`
        background-color: var(--text-primary);
      `,
    ],
  ],
  picture: [tw`flex items-center justify-center row-span-1`],
  meta: tw`flex flex-col justify-center row-span-1`,
  date: [tw`font-light mb-2`, tw`md:(text-sm mb-1)`, tw`2xl:(text-base mb-2)`],
  summary: [tw`overflow-hidden text-lg font-light tracking-wider leading-7.5`],
  callToAction: (website: string) => [
    tw`text-base font-thin border-2 border-white p-2 leading-5 text-center flex-shrink-0 px-10 cursor-pointer`,
    website === 'the-palm-beach' && [
      tw`uppercase text-white`,
      css`
        background-color: var(--gold);
      `,
    ],
  ],
}

function NewsItem({
  as = 'article',
  variant = 'dark',
  attributes,
}: NewsItemType) {
  const Component = as || 'article'

  const {
    call_to_action,
    heading,
    article_date,
    slug,
    summary,
    summary_image,
    terms_and_conditions,
  } = attributes

  const formattedDate = useFormatDate(article_date!)

  const website = useWebsite()
  const pathPrefix = ['the-palm-beach'].includes(website)
    ? '/the-palm-beach'
    : ''

  return (
    <Component css={styles.root(website, variant)} role="article">
      <Link href={`${pathPrefix}/news/${slug}`}>
        <a tw="grid grid-template-rows[200px minmax(200px, auto)]  grid-template-columns[1fr 1fr minmax(50%, 5fr) 1fr 1fr] h-full cursor-auto">
          <h3 className="sr-only">{heading}</h3>
          {summary_image && (
            <div tw="relative col-start-1 col-span-full row-start-1 row-span-1">
              <CoverImage
                tw="w-full h-full col-span-full row-span-full z-20 relative"
                src={summary_image.data.attributes.url}
                alt={
                  summary_image.data.attributes.alternativeText ||
                  `${heading} image`
                }
                sizes="320px"
              />
            </div>
          )}
          <div className="py-5 col-start-1 col-span-full row-start-2 row-span-1 flex flex-col justify-start items-center overflow-auto">
            <div tw="mb-auto grid grid-template-columns[1.25rem 1fr minmax(50%, 5fr) 1fr 1.25rem] w-full">
              <div className="col-start-2 col-span-3">
                <p tw="pb-2 text-xs font-semibold tracking-tight">
                  {article_date && (
                    <time dateTime={article_date}>{formattedDate}</time>
                  )}
                </p>
                <p className="overflow-hidden text-base md:text-base xl:text-lg xl:line-clamp-3 2xl:line-clamp-4 tracking-wider font-extralight leading-7.5 mb-5">
                  {summary}
                </p>
              </div>
            </div>
            <p css={styles.callToAction(website)}>
              {call_to_action ? call_to_action : 'Find Out More'}
            </p>
            <Icon
              variant="outline"
              icon="downArrow"
              tw="h-5.5 mt-2"
              aria-hidden
            />

            {terms_and_conditions && terms_and_conditions.summary && (
              <div
                tw="text-white text-left py-5 px-5 max-w-full"
                onClick={(event) => {
                  event.stopPropagation()
                }}>
                <TermsAndConditions theme="default" {...terms_and_conditions} />
              </div>
            )}
          </div>
        </a>
      </Link>
    </Component>
  )
}

export { NewsItem }
