import tw, { css } from 'twin.macro'
import { Heading } from '@/atoms/headings'
import { TickIcon } from './tick-icon'
import { RewardCard as RewardCardTypes } from '@/types/rewards'

const styles = {
    content: tw`text-black text-left`,
    checkedContent: tw` text-black text-left opacity-30`,
}

const RewardCard = ({ reward }: { reward: RewardCardTypes }) => {
    return (
        <div tw='bg-white h-full border border-white'>
            <Heading
                variant='h4'
                css={`
                    background: var(--${reward?.reward_level});
                `}
                tw='text-center h-14 px-4 flex items-center justify-center'
            >
                {reward?.reward_level.toUpperCase()}
            </Heading>
            <div tw='px-5 py-7 flex flex-col'>
                {reward?.benefits.map((benefit, index) => (
                    <div tw='flex  mb-3' key={index}>
                        <div
                            css={`
                                background: var(--${reward?.reward_level});
                            `}
                            tw='inline-block rounded-sm overflow-hidden w-4 h-4 flex-shrink-0 mt-1 mr-3 items-center justify-center flex'
                        >
                            {benefit.included && <TickIcon />}
                        </div>
                        <p css={!benefit.included ? styles.checkedContent : styles.content}>{benefit.benefit}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export { RewardCard }
