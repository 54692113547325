import { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';

import { Container } from '@/atoms/container';
import { LinkBox } from '@/atoms/link-box';

const styles = {
    container: tw`bg-theme-secondary py-16 py-16`,
    wrapper: tw`grid sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-0 lg:flex lg:flex-wrap lg:flex-col lg:flex-row lg:items-center lg:justify-center`,
};

function LinkBoxes({ data }: any) {
    const { link_box } = data;

    return (
        <Container variant='full' as='section' css={styles.container}>
            <Container css={styles.wrapper}>
                {link_box.map((item: any, index: any) => (
                    <LinkBox {...item} key={index} />
                ))}
            </Container>
        </Container>
    );
}

export { LinkBoxes };
