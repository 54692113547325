import tw, { css } from 'twin.macro'
import { EventsWhatsOn } from '@/types/events-whats-on'
import { IEvent } from '@/types/whats-on'
import { Container } from '@/atoms/container'
import { Heading } from '@/atoms/headings'
import { EventCard } from '@/molecules/event-card'

function EventsWhatsOnGrid({
  data,
  preview,
}: {
  data: EventsWhatsOn
  preview: boolean
}) {
  const { title, strapline, summary, events } = data
  return (
    <Container
      variant="full"
      tw="pt-36 pb-12 md:pb-24 relative text-theme-secondary bg-white">
      <Container>
        <header>
          <p tw="leading-6 tracking-wide">{strapline}</p>
          <Heading as="h3" variant="h2" tw="mt-2 leading-none">
            {title}
          </Heading>
          <p tw="mt-2 text-lg font-normal">{summary}</p>
        </header>
        <div tw="mt-12 mb-12 grid grid-flow-row grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5 gap-y-10">
          {events && events?.data?.length > 0 ? (
            events.data.map((event: IEvent) => (
              <EventCard event={event} key={event.id} />
            ))
          ) : (
            <p tw="text-center font-bold text-xl col-span-full">
              There are currently no events to display
            </p>
          )}
        </div>
      </Container>
    </Container>
  )
}

export { EventsWhatsOnGrid }
