// @ts-nocheck
import { useState } from 'react'
import Script from 'next/script'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import tw, { css, theme } from 'twin.macro'

import { CasinoPage } from '@/types/casino-page'
import { Form as FormTypes } from '@/types/contact-form'

import config from '@/lib/config'
import { getRecaptchaKey } from '@/lib/recaptcha'
import { RenderMarkdown } from '@/lib/render-markdown'

import { useCollection } from '@/hooks/use-cms-data'
import { useWebsite } from '@/hooks/use-website'
import { Button } from '@/atoms/button'
import { Container } from '@/atoms/container'
import { FieldError } from '@/atoms/field-error'
import { Heading } from '@/atoms/headings'
import { Label } from '@/atoms/label'
import { PlainHero } from '@/atoms/plain-hero'
import { SelectField } from '@/atoms/select-field'
import { Spinner } from '@/atoms/spinner'
import { TextareaField } from '@/atoms/textarea-field'
import { TextField } from '@/atoms/text-field'

import { SuccessModal } from '@/molecules/success-modal'
import { ErrorModal } from '@/molecules/error-modal'
import { ContactSchema, enquiryTypes } from '@/lib/contact-enquiry-schema'
import { GoogleRecaptchaText } from '@/atoms/google-recaptcha'

function useStartDate(years) {
  let today = new Date()
  today.setFullYear(today.getFullYear() - years)
  const date = today.toLocaleDateString('en-GB').split('/').reverse().join('-')

  return date
}

function ContactForm({ data }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const { query } = useRouter()
  const { slug } = query
  const website = useWebsite()
  const maxDate = useStartDate(18)
  let submitBtnText = 'Get in touch'

  if (website === 'crockfords') submitBtnText = submitBtnText.toUpperCase()

  const { captchaSiteKey } = config

  const { data: casinos } = useCollection('casinos', {
    fields: ['slug', 'page_title'],
    populate: null,
    pagination: {
      pageSize: 100,
    },
    sort: 'page_title:ASC',
  })

  const contactSchema = ContactSchema(casinos, website)

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormTypes>({
    mode: 'onBlur', // "onChange"
    resolver: yupResolver(contactSchema),
  })

  const onSubmit: SubmitHandler<FormTypes> = async (data, e) => {
    setLoading(true)
    const recaptchaToken = await getRecaptchaKey()
    const res = await fetch('/api/contact-enquiry', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'recaptcha_token': recaptchaToken,
        'website': website,
      },
      body: JSON.stringify(data),
    })
    if (res.status === 200) {
      setSuccessModalOpen(true)
      e?.target.reset()
    } else {
      const errors = await res.json()
      if (errors.inner) {
        errors.inner.forEach(
          ({ path, message }: { path: string; message: string }) => {
            /* @ts-ignore */
            setError(path, {
              type: 'server',
              message: message,
            })
          },
        )
      }
      setErrorModalOpen(true)
    }
    setLoading(false)
  }

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${captchaSiteKey}`}
        id="recaptcha-js"
        strategy="lazyOnload"
      />
      <section
        css={[
          tw`pb-24`,
          data.theme === 'light' && tw`bg-theme-accent bg-diamonds-pattern`,
          data.theme === 'dark' && tw`bg-theme-secondary`,
          website === 'genting-casinos' && tw`text-theme-inverse bg-[#F0F0F0]`,
          website === 'the-palm-beach' && tw`text-theme-primary`,
          data.theme === 'outline' && tw`bg-white`,
        ]}>
        {data ? (
          <Container
            css={[
              tw`py-10 text-center`,
              data.theme === 'outline' && tw`text-theme-accent`,
            ]}>
            {data.strapline && <p tw="mb-1">{data.strapline}</p>}
            <Heading
              as="h1"
              variant="h2"
              css={[data.theme === 'outline' && tw`text-theme-accent`]}>
              <RenderMarkdown>{data.heading}</RenderMarkdown>
            </Heading>

            {data.copy ? (
              <>
                <hr
                  css={[
                    tw`my-8 mx-auto border-b max-width[12rem] border-theme-secondary`,
                    website === 'the-palm-beach' && tw`border-theme-primary`,
                    website === 'crockfords' && tw`opacity-50`,
                    ((website === 'crockfords' && data.theme === 'dark') ||
                      data.theme === 'outline') &&
                      tw`border-theme-accent`,
                    website === 'crockfords' &&
                      data.theme === 'light' &&
                      tw`border-white`,
                  ]}
                />

                <div
                  css={[
                    tw`font-light mx-auto max-width[32rem]`,
                    data.theme === 'outline' && tw`text-black`,
                    website === 'genting-casinos' &&
                      css`
                        & a {
                          font-weight: bold;
                        }
                        & a:hover {
                          color: ${theme`colors.theme.accent`};
                        }
                      `,
                    website === 'the-palm-beach' &&
                      data.theme === 'dark' &&
                      css`
                        & a {
                          color: ${theme`colors.theme.accent`};
                        }
                        & a:hover {
                          background: white;
                        }
                      `,
                    website === 'the-palm-beach' &&
                      data.theme === 'light' &&
                      css`
                        & a {
                          color: black;
                        }
                        & a:hover {
                          background: white;
                        }
                      `,
                    website === 'crockfords' &&
                      css`
                        & a {
                          color: ${data.theme === 'light'
                            ? 'black'
                            : theme`colors.theme.accent`};
                        }
                        & a:hover {
                          background: white;
                          color: ${theme`colors.theme.secondary`};
                        }
                      `,
                  ]}>
                  <RenderMarkdown>{data.copy}</RenderMarkdown>
                </div>
              </>
            ) : null}
          </Container>
        ) : null}
        <Container>
          <SuccessModal open={successModalOpen} setOpen={setSuccessModalOpen} />
          <ErrorModal open={errorModalOpen} setOpen={setErrorModalOpen} />
          <main
            css={[
              tw`py-16 px-5 mb-8 md:(px-12 mb-0) h-full rounded-xl bg-white max-w-[556px] m-auto`,
              data.theme === 'outline' && tw`border border-theme-accent`,
            ]}>
            <form
              data-testid="contact-form"
              className="max-w[556px] text-black"
              onSubmit={handleSubmit(onSubmit)}>
              <fieldset className="mb-10" disabled={loading}>
                <Heading
                  variant="h3"
                  css={[
                    tw`mb-4 text-theme-accent text-center`,
                    website === 'crockfords' &&
                      data.theme === 'dark' &&
                      tw`text-theme-secondary`,
                  ]}>
                  My Details
                </Heading>
                <div className="grid grid-cols-6 gap-x-0 sm:gap-x-10 md:gap-x-20 gap-y-8">
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="first-name" required={true}>
                      First name
                    </Label>
                    <TextField
                      autoComplete="given-name"
                      placeholder="First Name"
                      {...register('firstName')}
                    />
                    {errors?.firstName && (
                      <FieldError
                        name="firstName"
                        message={errors.firstName.message}
                      />
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="last-name" required={true}>
                      Last name
                    </Label>
                    <TextField
                      autoComplete="family-name"
                      placeholder="Last Name"
                      {...register('lastName')}
                    />
                    {errors?.lastName && (
                      <FieldError
                        name="lastName"
                        message={errors.lastName.message}
                      />
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="email" required={true}>
                      Email
                    </Label>
                    <TextField
                      type="email"
                      autoComplete="email"
                      placeholder="Email Address"
                      {...register('email')}
                    />
                    {errors?.email && (
                      <FieldError name="email" message={errors.email.message} />
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="contact-number" required={true}>
                      Contact Number
                    </Label>
                    <TextField
                      type="tel"
                      autoComplete="tel-national"
                      placeholder="Phone Number"
                      {...register('telephone')}
                    />
                    {errors?.telephone && (
                      <FieldError
                        name="telephone"
                        message={errors.telephone.message}
                      />
                    )}
                  </div>
                </div>
              </fieldset>
              <fieldset disabled={loading}>
                <Heading
                  variant="h3"
                  css={[
                    tw`mb-4 text-theme-accent text-center`,
                    website === 'crockfords' &&
                      data.theme === 'dark' &&
                      tw`text-theme-secondary`,
                  ]}>
                  My Enquiry
                </Heading>
                <div className="grid grid-cols-6 gap-x-0 md:gap-x-20 gap-y-8">
                  <div className="col-span-6">
                    <Label htmlFor="enquiryType" required={true}>
                      Nature of enquiry
                    </Label>
                    <SelectField
                      placeholder="Select nature of enquiry"
                      {...register('enquiryType')}>
                      {enquiryTypes.map((type, idx) => (
                        <option key={idx + type}>{type}</option>
                      ))}
                    </SelectField>
                    {errors?.enquiryType && (
                      <FieldError
                        name="enquiryType"
                        message={errors.enquiryType.message}
                      />
                    )}
                  </div>
                  {website === 'genting-casinos' && (
                    <div className="col-span-6">
                      <Label htmlFor="casinoName" required={true}>
                        Casino
                      </Label>
                      <SelectField
                        placeholder="Select Casino"
                        /* @ts-ignore */
                        defaultValue={slug}
                        {...register('casinoName')}>
                        {casinos?.data?.map(
                          ({ id, attributes }: CasinoPage) => {
                            return (
                              <option key={id} value={attributes.slug}>
                                {attributes.page_title}
                              </option>
                            )
                          },
                        )}
                      </SelectField>
                      {errors?.casinoName && (
                        <FieldError
                          name="casinoName"
                          message={errors.casinoName.message}
                        />
                      )}
                    </div>
                  )}
                  <div className="col-span-6">
                    <Label htmlFor="additionalDetails" required={true}>
                      Additional details
                    </Label>
                    <TextareaField
                      placeholder="Add your message here"
                      {...register('additionalDetails')}
                    />
                    {errors?.additionalDetails && (
                      <FieldError
                        name="additionalDetails"
                        message={errors.additionalDetails.message}
                      />
                    )}
                  </div>
                </div>
              </fieldset>

              <div className="mt-10 md:mt-12 grid grid-cols-6 justify-center">
                <Button
                  type="submit"
                  theme={
                    website === 'crockfords' || data.theme === 'outline'
                      ? 'default'
                      : 'accent'
                  }
                  variant="contained"
                  disabled={loading}
                  css={[
                    tw`col-span-6 md:(col-span-2 col-start-3) flex h-full justify-center items-center font-light text-sm`,
                    ((website === 'crockfords' && data.theme === 'light') ||
                      data.theme === 'outline') &&
                      tw`border-theme-accent text-theme-accent`,
                    website === 'crockfords' &&
                      data.theme === 'dark' &&
                      tw`border-[#860237]`,
                  ]}>
                  {loading ? <Spinner margin="none" /> : submitBtnText}
                </Button>
              </div>
            </form>
            <GoogleRecaptchaText />
          </main>
        </Container>
      </section>
    </>
  )
}

export { ContactForm }
