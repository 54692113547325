import tw from 'twin.macro'

import { SlidingNav } from '@/atoms/sliding-nav'

import { GameSection } from '@/molecules/game-section'
import { Container } from '@/atoms/container'
import { GameSectionsType } from '@/types/game-sections-type'
import { useEffect, useRef } from 'react'

type Entries = {
  isIntersecting: boolean
  target: { id: string }
}

const links = [
  {
    hash: '#table-games',
    text: 'Table Games',
  },
  {
    hash: '#slots',
    text: 'Slots',
  },
  {
    hash: '#e-tables',
    text: 'E-Tables',
  },
  {
    hash: '#jackpots',
    text: 'Jackpots',
  },
]

export function CasinoGames({ data }: { data: GameSectionsType }) {
  const { casino_games_categories: GameSections } = data

  const slideNavRef = useRef()
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: `0px 0px -99% 0px`,
    }

    const sections = document.querySelectorAll('[data-sectionId]')
    function observerCallback(entries: Entries[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // prettier-ignore
          {/*@ts-ignore*/}
          slideNavRef?.current?.setActiveScroll(entry.target.id)
        }
      })
    }

    let observer = new IntersectionObserver(observerCallback, options)

    sections.forEach((section) => {
      observer.observe(section)
    })
  }, [])

  return (
    <Container
      variant="full"
      tw="bg-theme-accent pt-10 pb-20 bg-diamonds-pattern">
      <Container>
        <SlidingNav data={links} ref={slideNavRef} />
        <div>
          {GameSections.data.map((gameSection) => (
            <GameSection
              key={gameSection.attributes.title}
              id={gameSection.attributes.slug}
              title={gameSection.attributes.title}
              data={gameSection.attributes.casino_games.data}
            />
          ))}
        </div>
      </Container>
    </Container>
  )
}
