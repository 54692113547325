import { XIcon } from '@heroicons/react/outline'

import { Modal } from '@/molecules/base-modal'

function ErrorModal(props: { open: boolean; setOpen: () => void }) {
  return (
    <Modal {...props} data-testid="error-modal">
      <Modal.Icon>
        <XIcon className="h-20 w-20 text-theme-secondary" />
      </Modal.Icon>
      <Modal.Title>Error</Modal.Title>
      <Modal.Description>
        Looks like there's a problem, please check the form and ensure all
        fields are completed before trying again.
      </Modal.Description>
    </Modal>
  )
}

export { ErrorModal }
