import tw, { css } from 'twin.macro'
import Image from 'next/image'
import { formatCurrency } from '@/lib/format-currency'

type TPartialImage = {
  url: string
  alternativeText?: string
}

interface TJackpotComposedImage {
  type: 'Mega' | 'Major' | 'Mini'
  jpImg: TPartialImage
  borderImg: TPartialImage
  jpAmount: string
}

const styles = {
  imageComposeArea: [
    tw`grid h-52 relative place-items-center place-content-center`,
    css`
      grid-template-rows: 60px 98px;
      grid-template-columns: 32px 184px 35px;
    `,
  ],
  jpCrownImg: [
    tw`col-start-2 col-span-1 row-start-1 z-30 h-[200px] w-[200px] relative`,
  ],
  textPosition: [
    tw`col-start-2 col-span-1 row-start-2 w-48 h-12 grid place-items-center bg-theme-secondary z-10 relative -top-1`,
  ],
  lightText: [tw`text-white font-light text-sm mt-1`],
  jackpotNum: [tw`text-white font-bold`],
}

export function JackpotComposedImage({
  type,
  jpImg,
  jpAmount,
  borderImg,
}: TJackpotComposedImage) {
  const result = formatCurrency({ amount: jpAmount })

  return (
    <div css={styles.imageComposeArea}>
      <div css={styles.jpCrownImg}>
        <Image
          src={jpImg.url}
          alt={jpImg.alternativeText || `${type}Jackpot Image`}
          priority
          layout="fill"
          objectFit="contain"
        />
      </div>
      <div className="col-span-full row-start-2 row-span-1 z-0 absolute -top-2">
        <img src={borderImg.url} />
      </div>
      <div css={styles.textPosition}>
        <p css={styles.lightText}>Current Jackpot</p>
        <p className="text-white font-bold tracking-wider text-lg leading-none mb-2">
          {result}
        </p>
      </div>
    </div>
  )
}
