import Image from 'next/image'

import tw from 'twin.macro'

import { VideoPlaceholderTypes } from '@/types/video-gallery-placeholder'

import PlayIcon from '@/atoms/play-icon'

export const VideoGalleryPlaceholder = ({
  video,
  index,
  handleVideo,
  getTimeSince,
}: {
  video: VideoPlaceholderTypes
  index: number
  handleVideo: Function
  getTimeSince: Function
}) => {
  return (
    <div key={index}>
      <div
        tw="relative aspect-w-16 aspect-h-9 mb-5 cursor-pointer border-[1px] border-solid border-white"
        onClick={(e) => handleVideo(index, e)}>
        <Image
		      alt=""
          tw="transform"
          src={video.placeholder.data.attributes.url}
          layout="fill"
          objectFit="cover"
        />
        <PlayIcon tw="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px] z-20" />
        {video.duration && (
          <span tw="absolute w-[53px] h-[26px] text-base leading-6 font-bold right-[10px] bottom-[10px] top-auto left-auto flex items-center justify-center bg-black rounded-md px-4">
            {video.duration}
          </span>
        )}
      </div>
      <h5 tw="mb-[10px] text-base">{video.title.substring(0, 80)}</h5>
      <p tw="text-white opacity-50 text-sm leading-6 mb-5 md:mb-0">
        {getTimeSince(video.formats[0].video_format.data.attributes.createdAt)}
      </p>
    </div>
  )
}
