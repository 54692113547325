import { useQuery } from 'react-query'

import config from '@/lib/config'
import { FormatDateTime } from '@/lib/format-date-time'

async function getJackpots() {
  const { jackpotsApi } = config

  const res = await fetch(jackpotsApi)
  const data = await res.json()
  const { values, lastModified } = data

  const time = FormatDateTime(lastModified, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })

  const date = FormatDateTime(lastModified, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })

  const friendlyLastModified = `${time} ${date}`

  return { values, friendlyLastModified }
}

const useJackpots = () => {
  return useQuery<any, Error>('jackpots', () => getJackpots())
}

export { getJackpots, useJackpots }
