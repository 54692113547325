import tw from 'twin.macro'

import { useWebsite } from '@/hooks/use-website'

import { NextLink } from '@/atoms/next-link'

export function GoogleRecaptchaText() {
  const website = useWebsite()
  return (
    <div className={`mt-10 text-center text-xs ${website === 'crockfords' ? 'text-black' : 'text-theme-secondary'}`}>
      <p className="mb-6 max-w-xs mx-auto">
        Personal data shared with us as part of this form will be processed in
        accordance with the{' '}
        <a href="/privacy-policy/" target="_blank" rel="noreferrer">
          Genting Privacy Policy
        </a>
      </p>
      <p>This site is protected by reCAPTCHA and the </p>
      <span>
        <NextLink
          to="https://policies.google.com/privacy"
          tw="hover:(text-theme-accent)"
          rel="noopener noreferrer">
          {' '}
          Google Privacy Policy
        </NextLink>{' '}
        and
      </span>
      <span>
        <NextLink
          to="https://policies.google.com/terms"
          tw="hover:(text-theme-accent)"
          rel="noopener noreferrer">
          {' '}
          Terms of Service
        </NextLink>{' '}
        apply.
      </span>
    </div>
  )
}
