import tw, { css, theme } from 'twin.macro'

import { ContentBoxes as ContentBoxesType } from '@/types/content-boxes'

import { ContentBox } from '@/atoms/content-box'
import { Container } from '@/atoms/container'
import { Slider, SliderItem } from '@/atoms/slider' 

import { useMediaQuery } from '@/hooks/use-media-query'

const styles = {
  container: ({ isLargeScreen, itemsLength} : { isLargeScreen: boolean, itemsLength: number }) => [
    tw`pb-16 bg-theme-secondary`,
    isLargeScreen && itemsLength <= 4 && tw`xl:pb-0`
  ],
  slider: ({ isLargeScreen, itemsLength} : { isLargeScreen: boolean, itemsLength: number }) => [
    tw`py-16 flex -mx-3 overflow-y-auto`,
    isLargeScreen && itemsLength <= 4 && tw`!overflow-x-hidden`
  ],
  sliderItem: [
    tw`flex-shrink-0 mx-3
      (width[calc(100vw - 40%)]  height[220px])
    `,
    css`                                   
      @media (min-width: ${theme`screens.md`}) {  
        width: calc(calc(100% / 3) - ${theme`gap.5`});
      }          
      @media (min-width: ${theme`screens.xl`}) {  
        width: calc(calc(${theme`screens.xl`} / 4) - ${theme`gap.5`});
      }      
    `,  
  ]
}

function ContentBoxes({ data: { link_card } } : { data: ContentBoxesType }) {
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`);

  return (
    <Container variant="full" as="section" css={styles.container({ isLargeScreen, itemsLength: link_card.length })}>
      <Container>
        <Slider css={styles.slider({ isLargeScreen, itemsLength: link_card.length  })}>
          {link_card.map((item) => (
            <SliderItem key={item.id} css={styles.sliderItem}>
              <ContentBox {...item} />
            </SliderItem>
          ))}
        </Slider>
      </Container>
    </Container>
  )
}

export { ContentBoxes }