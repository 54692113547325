
import { forwardRef } from 'react'
import tw from 'twin.macro'
import { UseFormRegister } from 'react-hook-form'

import {  TextareaField as TextareaFieldTypes } from '@/types/forms'

const TextareaField = forwardRef<
  HTMLTextAreaElement,
  TextareaFieldTypes & ReturnType<UseFormRegister<TextareaFieldTypes>>  
>(({ name, placeholder, defaultValue = '', ...rest} : TextareaFieldTypes, ref) => {
  return (
    <textarea
      name={name}
      id={name}
      tw="mt-1 h-28 focus:(outline-none ring-2 ring-red-500 border-red-500) block w-full border-transparent bg-[#F0F0F0] placeholder-gray-300 disabled:cursor-not-allowed"
      defaultValue={defaultValue}
      placeholder={placeholder}
      {...rest}
      ref={ref}
    />    
  )
})

export { TextareaField }