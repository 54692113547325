import { useState } from 'react'
import tw, { css, theme } from 'twin.macro'

import { OnlineGames as OnlineGamesType } from '@/types/online-games'
import { Tile } from '@/types/tile'

import { Container } from '@/atoms/container'
import { Slider, SliderItem } from '@/atoms/slider'
import { Heading } from '@/atoms/headings'
import { GamingTile } from '@/atoms/gaming-tile'

import { useMediaQuery } from '@/hooks/use-media-query'

const styles = {
  container: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`pb-16 bg-theme-secondary`,
    isLargeScreen && itemsLength <= 4 && tw`xl:pb-0`,
  ],
  slider: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`pb-8 md:pb-12 grid grid-flow-col auto-cols-max gap-3 mt-12 md:mt-8`,
    isLargeScreen && itemsLength <= 4 && tw`!overflow-x-hidden pb-0 md:pb-0`,
    css`
      scroll-snap-type: x;
    `,
  ],
  sliderItem: [
    tw`flex-shrink-0 (width[calc(100vw - 40%)])  flex justify-center items-center border-theme-secondary overflow-hidden 
    `,
    css`
      width: calc(
        ${theme`screens.xl`} / 6 - ${theme`gap.5`} + (${theme`gap.5`} / 6)
      );
    `,
  ],
}

const OnlineGames = ({ data }: { data: OnlineGamesType }) => {
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`)
  const [viewAll, setViewAll] = useState(false)

  const {
    heading,
    online_games_category: { games },
  } = data

  return (
    <Container
      variant="full"
      tw="bg-theme-accent pt-10 pb-20 bg-diamonds-pattern">
      <Container>
        <div className="mt-4 md:mt-10 flex flex-col md:flex-row justify-between">
          <Heading variant="h3" tw="inline-block text-center mb-8">
            {heading}
          </Heading>
          <button
            tw="text-base md:text-xl text-center font-light tracking-wider font-medium"
            onClick={() => setViewAll(!viewAll)}>
            {viewAll ? 'View less' : 'View all'}
          </button>
        </div>
        {viewAll ? (
          <div className="grid grid-cols-1  xs:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 auto-cols-max mt-12 md:mt-8 gap-3">
            {games.map((game: Tile) => (
              <GamingTile key={game.id} {...game} />
            ))}
          </div>
        ) : (
          <Slider
            css={styles.slider({ isLargeScreen, itemsLength: games.length })}>
            {games?.map((game: Tile) => (
              <SliderItem css={styles.sliderItem} key={game.id}>
                <GamingTile key={game.id} {...game} />
              </SliderItem>
            ))}
          </Slider>
        )}
      </Container>
    </Container>
  )
}

export { OnlineGames }
