import tw, { css, theme } from 'twin.macro'

import { IPlatform } from '@/types/gaming-information-types'

import { useWebsite } from '@/hooks/use-website'

import { GamingInformationTab } from '@/molecules/gaming-information-tab'
import { PlatformTabPanels } from '@/molecules/platform-tab-panels'
import { Container } from '@/atoms/container'

function GamingInformation({ data }: { data: any }) {
  const website = useWebsite()

  return (
    <section className="pb-16 bg-theme-secondary grid-cols-a">
      {data.Platform ? (
        <Container tw="mr-0 md:mx-0 md:ml-5 xl:mx-auto">
          <div
            css={[
              tw`max-w-screen-xl mx-auto py-20 bg-transparent overflow-y-auto lg:overflow-y-hidden grid space-x-3
              grid-cols-[repeat(3,calc(100vw - 30%)) 1.25rem]
              md:grid-cols-[repeat(auto-fit,minmax(200px,1fr)) 1.25rem]
              xl:grid-cols-[repeat(auto-fit,minmax(200px,1fr))] grid-rows-[3rem repeat(3,minmax(50px,auto)) 3rem]`,
              website === 'crockfords' && tw`-mt-40`,
              css`
                scroll-snap-type: x mandatory;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                  display: none; /* Chrome, Safari and Opera */
                }
              `,
            ]}>
            {data.Platform.map((platform: IPlatform) => (
              <GamingInformationTab {...platform} key={platform.id} />
            ))}
          </div>
          {data.Platform.map((platform: IPlatform) => (
            <PlatformTabPanels platform={platform} key={platform.id} />
          ))}
        </Container>
      ) : null}
    </section>
  )
}

export { GamingInformation }
