export const SortIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="currentColor">
        <path d="M.237 5.503l1.155 1.215 3.696-3.512v20.738h1.676V3.077l3.686 3.63 1.176-1.192L6.03 0z" />
        <path d="M22.61 17.284l-3.697 3.512V.06h-1.676v20.865l-3.686-3.63-1.176 1.192L17.971 24l5.792-5.503z" />
      </g>
    </svg>
  );
};
