import { ReactNode } from 'react'
import tw, { css } from 'twin.macro'
import { ContentBlock } from '@/types/content-block'
import { ContentBoxes as ContentBoxesTypes } from '@/types/content-boxes'
import { ContentFullWidth as ContentFullWidthTypes } from '@/types/content-full-width'
import { ContentTwoColumns as ContentTwoColumnsTypes } from '@/types/content-two-columns'
import { ContentWithImage as ContentWithImageType } from '@/types/content-with-image'
import { FaqBlock as FaqBlockTypes } from '@/types/faq-block'
import { GameSectionsType } from '@/types/game-sections-type'
import { HeaderSmallCopy as HeaderSmallCopyTypes } from '@/types/header-small-copy'
import { Heading as HeadingType } from '@/types/heading'
import { Hero as HeroType } from '@/types/hero'
import { IFrame as IframeTypes } from '@/types/iframe'
import { LinkGroupWithHeadingCopy as LinkGroupTypes } from '@/types/link-group-with-heading-copy'
import { News as NewsTypes } from '@/types/news'
import { OnlineGames as OnlineGamesType } from '@/types/online-games'
import { PlainHero as PlainHeroTypes } from '@/types/plain-hero'
import { PromotionBanner as PromotionBannerType } from '@/types/promotional-banner'
import { Resorts as ResortsTypes } from '@/types/resorts-type'
import { Rewards as RewardTypes } from '@/types/rewards'
import { ShopsList as ShopsListType } from '@/types/shops'
import { TabsProps } from '@/types/tabs'
import {
  TeamSlider as TeamTypes,
  TeamDetails as TeamDetailsTypes,
} from '@/types/team'
import { Video as VideoType } from '@/types/video'
import { VideoGallery as VideoGalleryType } from '@/types/video-gallery'

import { useHash } from '@/hooks/use-hash'
import { useWebsite } from '@/hooks/use-website'

import { IFrame } from '@/atoms/iframe'
import { PlainHero } from '@/atoms/plain-hero'
import { Container } from '@/atoms/container'

import { GamingInformation } from '@/organisms/gaming-information'
import { Jackpots } from '@/organisms/jackpots'
import { Menu } from '@/organisms/menu'

import { ButtonsCTA } from '@/molecules/buttons-cta'
import { ContentWithGallery } from '@/molecules/content-with-gallery'
import { ContactForm } from '@/molecules/contact-form'
import { CasinoGames } from '@/molecules/casino-gaming'
import { ContentBoxes } from '@/molecules/content-boxes'
import { ContentFullWidth } from '@/molecules/content-full-width'
import { ContentHeroImage } from '@/molecules/content-hero-image'
import { ContentSplitCopyImage } from '@/molecules/content-with-image'
import { ContentTwoColumn } from '@/molecules/content-two-column'
import { FaqBlock } from '@/molecules/faq-block'
import { GentingBenefits } from '@/molecules/genting-benefits'
import { HeaderContent } from '@/molecules/header-content'
import { HeaderSmallCopy } from '@/molecules/header-small-copy'
import { LinkBoxes } from '@/molecules/link-boxes'
import { LinkGroupHeadingCopy } from '@/molecules/link-group-with-heading-copy'
import { OnlineGames } from '@/molecules/online-games'
import { PromotionBanner } from '@/molecules/promotion-banner'
import { ResortsDropdown } from '@/molecules/resorts-dropdown'
import { Rewards } from '@/molecules/rewards'
import { Shops } from '@/molecules/shops'
import { SlickPromotionsCarousel } from '@/molecules/slick-promotions-carousel'
import { TabbedContent } from '@/molecules/tabbed-content'
import { TeamDetails } from '@/molecules/team-details'
import { TeamGrid } from '@/molecules/team-grid'
import { TeamSlider } from '@/molecules/team-slider'
import { VideoGallery } from '@/molecules/video-gallery'
import { VideoPlayer } from '@/molecules/video-player'

import { NewsGrid } from '@/organisms/homepage-news-grid'
import { CasinoNavigation } from '@/molecules/casino-navigation'
import { JackpotTabs } from '@/molecules/jackpot-tabs'

import { EventsWhatsOnGrid } from '@/organisms/events-whats-on-grid'

function ContentZone({ blocks }: { blocks: ContentBlock[] }) {
  const website = useWebsite()
  const { getHash } = useHash()

  return (
    <>
      {(blocks || []).map((block: ContentBlock, blockIndex: number) => {
        if (block.__component === 'jackpots.jackpots') {
          return <JackpotTabs data={block as TabsProps} key={blockIndex} />
        }

        if (block.__component === 'content.buttons-cta') {
          return <ButtonsCTA data={block as HeroType} key={blockIndex} />
        }
        if (block.__component === 'jackpots.jackpots') {
          return <JackpotsTabbedContent key={blockIndex} data={block} />
        }

        if (block.__component === 'casinos.copy-with-gallery')
          return (
            <>
              <Container
                id={block.menu_label && getHash(block.menu_label)}
                variant="full"
                tw="pb-10 pt-18 bg-theme-secondary">
                <Container tw="flex justify-center">
                  <ContentWithGallery data={block} key={blockIndex} />
                </Container>
              </Container>
            </>
          )
        if (block.__component === 'content.heading') {
          return <HeaderContent data={block as HeadingType} key={blockIndex} />
        }
        if (block.__component === 'content.tabs') {
          return (
            <TabbedContent
              id={block.menu_label && getHash(block.menu_label)}
              key={blockIndex}
              data={block as TabsProps}
              tw="text-black"
            />
          )
        }

        if (block.__component === 'content.main-heading') {
          return <ContentHeroImage data={block as HeroType} key={blockIndex} />
        }

        if (block.__component === 'content.get-in-touch-form') {
          return <ContactForm data={block} />
        }

        if (block.__component === 'content.link-cards') {
          return (
            <ContentBoxes data={block as ContentBoxesTypes} key={blockIndex} />
          )
        }

        if (block.__component === 'corporate.link-group-with-heading-copy') {
          return (
            <LinkGroupHeadingCopy
              data={block as LinkGroupTypes}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'content.news-grid') {
          return <NewsGrid data={block as NewsTypes} key={blockIndex} />
        }

        if (block.__component === 'corporate.team-slider') {
          return <TeamSlider data={block as TeamTypes} key={blockIndex} />
        }

        if (block.__component === 'corporate.teams-grid') {
          return <TeamGrid data={block as TeamTypes} key={blockIndex} />
        }

        if (block.__component === 'corporate.team-details') {
          return (
            <TeamDetails data={block as TeamDetailsTypes} key={blockIndex} />
          )
        }

        if (block.__component === 'content.full-width-copy') {
          return (
            <ContentFullWidth
              data={block as ContentFullWidthTypes}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'content.two-column-copy') {
          return (
            <ContentTwoColumn
              data={block as ContentTwoColumnsTypes}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'content.link-boxes') {
          return <LinkBoxes data={block} key={blockIndex} />
        }

        if (block.__component === 'content.heading-small-copy') {
          return (
            <HeaderSmallCopy
              data={block as HeaderSmallCopyTypes}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'content.iframe') {
          return (
            <IFrame
              id={block.menu_label && getHash(block.menu_label)}
              data={block as IframeTypes}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'members.reward-schemes') {
          return <Rewards data={block as RewardTypes} key={blockIndex} />
        }

        if (block.__component === 'content.video-player') {
          return (
            <Container
              variant="full"
              tw="pb-10 pt-18 bg-theme-secondary bg-diamonds-pattern">
              <Container tw="flex justify-center">
                <VideoPlayer data={block as VideoType} key={blockIndex} />
              </Container>
            </Container>
          )
        }

        if (block.__component === 'content.video-gallery') {
          return (
            <VideoGallery data={block as VideoGalleryType} key={blockIndex} />
          )
        }

        if (block.__component === 'content.copy-with-image') {
          return <ContentSplitCopyImage data={block as ContentWithImageType} />
        }

        if (block.__component === 'online-gaming.promotional-banner') {
          return (
            <PromotionBanner
              data={block as PromotionBannerType}
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'online-gaming.games') {
          return (
            <OnlineGames data={block as OnlineGamesType} key={blockIndex} />
          )
        }

        if (block.__component === 'entertainment.resorts-dropdown') {
          return (
            <ResortsDropdown data={block as ResortsTypes} key={blockIndex} />
          )
        }

        if (block.__component === 'entertainment.shops-list') {
          return <Shops data={block as ShopsListType} key={blockIndex} />
        }

        if (block.__component === 'content.plain-hero-header') {
          return <PlainHero data={block as PlainHeroTypes} key={blockIndex} />
        }

        if (block.__component === 'casino-gaming.games') {
          return (
            <CasinoGames data={block as GameSectionsType} key={blockIndex} />
          )
        }

        if (block.__component === 'content.faq-block') {
          return (
            <FaqBlock
              data={block as FaqBlockTypes}
              variant="page"
              key={blockIndex}
            />
          )
        }

        if (block.__component === 'homepage.genting-benefits-list') {
          return <GentingBenefits data={block} key={blockIndex} />
        }

        if (block.__component === 'content.carousel-video-img') {
          const nextIndex = blockIndex + 1
          return (
            <SlickPromotionsCarousel
              data={block}
              key={blockIndex}
              casinoNavigation={
                website === 'the-palm-beach' &&
                blocks[nextIndex].__component === 'casino-navigation'
              }
            />
          )
        }

        if (block.__component === 'homepage.jackpot-aces') {
          return <Jackpots data={block} key={blockIndex} />
        }

        if (block.__component === 'homepage.game-info-platforms') {
          return <GamingInformation data={block} key={blockIndex} />
        }

        if (block.__component === 'content.menu') {
          return (
            <Menu
              id={block.menu_label && getHash(block.menu_label)}
              data={block}
              key={blockIndex}
            />
          )
        }

        if (
          block.__component === 'casino-navigation' &&
          website !== undefined
        ) {
          return <CasinoNavigation key={blockIndex} blocks={blocks} />
        }

        if (
          block.__component === 'entertainment.events-grid' &&
          website !== undefined
        ) {
          return <EventsWhatsOnGrid data={block} key={blockIndex} />
        }

        return null
      })}
    </>
  )
}

export { ContentZone }
