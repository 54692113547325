import * as yup from 'yup'
import { CasinoPage } from '@/types/casino-page'

const getClubValues = (casinos: { data: CasinoPage[] }) => {
  return casinos?.data?.map((item) => item.attributes.slug) || []
}

export const enquiryTypes = [
  'Planning a visit',
  'A previous visit',
  'My Genting App',
  'Membership',
  'Rewards & Offers',
  'Complaint/Dispute',
  'Private Hire',
  'Other',
]

export const ContactSchema = (
  casinos: { data: CasinoPage[] },
  website: string
) => {
  let contactSchema = {
    firstName: yup
      .string()
      .required('First name is required')
      .max(80, 'First name is too long'),
    lastName: yup
      .string()
      .required('Last name is required')
      .max(80, 'Last name is too long'),
    email: yup
      .string()
      .required('Email address is required')
      .max(200, 'Email address is too long')
      .matches(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/, {
        message: 'Email address is not valid',
        excludeEmptyString: true,
      }),
    telephone: yup
      .string()
      .required('Contact number is required')
      .matches(
        /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/,
        'Contact number is not valid'
      ),
    enquiryType: yup
      .string()
      .required('Enquiry type is required')
      .oneOf(enquiryTypes, 'Enquiry option not found, please try another'),
    additionalDetails: yup.string().required('Additional details are required'),
  }

  // if (website === 'the-palm-beach') {
  //   contactSchema = {
  //     ...contactSchema,
  //     dob: yup.string().required('Date of Birth is required'),
  //   }
  // }

  if (website === 'genting-casinos') {
    contactSchema = {
      ...contactSchema,
      casinoName: yup
        .string()
        .required('A casino is required')
        .oneOf(
          getClubValues(casinos),
          'Casino not found, please try another location'
        ),
    }
  }

  return yup.object(contactSchema).required()
}
