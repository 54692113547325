import tw, { css, theme } from 'twin.macro';
import Link from 'next/link';

type CardProps = {
    title: string;
    link: string;
};

const styles = {
    card: [
        tw`border border-white py-4 px-4 flex-auto md:max-w-xs cursor-pointer text-center lg:mb-4 lg:last:mb-4 lg:mr-4 hover:bg-white hover:text-[#7b030b] transition-colors duration-300 flex items-center justify-center`,
    ],
};

function LinkBox({ title, link }: CardProps) {
    return (
        <Link href={link}>
            <a css={styles.card}>{title}</a>
        </Link>
    );
}

export { LinkBox };
