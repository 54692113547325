import tw from 'twin.macro';

import { HeaderSmallCopy as HeaderSmallCopyTypes } from '@/types/header-small-copy'

import { Container } from '@/atoms/container';
import { Heading } from '@/atoms/headings';

import { RenderMarkdown } from '@/lib/render-markdown';

export function HeaderSmallCopy({ data } : { data: HeaderSmallCopyTypes }) {
    return (
        <section tw='bg-gray-100'>
            <Container tw='py-10 text-theme-inverse'>
                <Heading as='h3' variant='h2' tw='mt-2 text-theme-accent leading-none text-center'>
                    <RenderMarkdown>{data.heading}</RenderMarkdown>
                </Heading>
                <div tw='border-b border-black max-w-[192px] mx-auto my-12'></div>
                <p className='leading-6 tracking-wide max-w-md text-center mx-auto'>{data.heading_copy}</p>
            </Container>
        </section>
    );
}
