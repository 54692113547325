import { ReactNode } from 'react'
import tw, { css } from 'twin.macro'
import { NextLink } from '@/atoms/next-link'
import Image from 'next/image'

import { Image as ImageProps } from '@/types/image'

import { useWebsite } from '@/hooks/use-website'

const styles = {
  card: (website: any) => [
    tw`border
      grid grid-cols-1 grid-template-rows[295px 48px] relative   
    `,
    css`
      border-color: #d5c59f;
    `,
    website === 'crockfords' && tw`border-white`,
  ],
  heading: (website: any) => [
    tw`col-span-full row-start-2 uppercase font-light flex items-center justify-center text-center z-50 relative
      text-xl pt-2 h-12
      bg-theme-gold`,
    ['the-palm-beach', 'crockfords'].includes(website) && tw`bg-theme-secondary`,
    website === 'crockfords' && tw`font-baskerville`
  ],
  overlay: [
    tw`row-span-full col-span-full z-40 relative bg-opacity-50`,
    css`
      background-color: rgba(154, 135, 87, 0.6);
    `,
  ],
  role: tw`px-10 py-6 flex text-center justify-center text-lg font-light`,
}

function TeamImage({ image, name }: { image: ImageProps; name: string }) {
  const website = useWebsite()
  return (
    <div css={styles.card(website)}>
        <Image
          src={image.data.attributes.url}
          alt={image.data.attributes.alternativeText}
          layout="fill"
          objectFit="cover"
        />
      <div css={styles.heading(website)}>{name}</div>
    </div>
  )
}

function Role({ children }: { children: ReactNode }) {
  return <div css={styles.role}>{children}</div>
}

function TeamBox({ slug, children }: { slug: string; children: ReactNode }) {
  const website = useWebsite()
  return (
    <NextLink to={website === 'genting-casinos' ? `/${slug}` : `/${website}/${slug}`}>
      <div>{children}</div>
    </NextLink>
  )
}

TeamBox.Image = TeamImage
TeamBox.Role = Role

export { TeamBox }
