
import { CheckIcon } from '@heroicons/react/outline'

import { Modal } from '@/molecules/base-modal'

function SuccessModal(props: {
  open: boolean,
  setOpen: (boolean: boolean) => void
}) {
  return (
    <Modal {...props}  data-testid="success-modal">    
      <Modal.Icon>
        <CheckIcon className="h-20 w-20 text-theme-secondary"/>
      </Modal.Icon>      
      <Modal.Title>
      Thank You
      </Modal.Title>                   
      <Modal.Description>
        Thank you for contacting us, we&apos;ll be in touch soon
      </Modal.Description>              
      </Modal>    
  )
}

export { SuccessModal }
