import { createRef, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import tw, { theme, css } from 'twin.macro'

import { TPromotions } from '@/types/homepage'

import { useWebsite } from '@/hooks/use-website'

import { AHref } from '@/atoms/a-href'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Heading } from '@/atoms/headings'
import { TermsAndConditions } from './ts-and-cs'

export function SlickPromotionsCarousel({
  data,
  casinoNavigation,
}: {
  data: any
  casinoNavigation?: boolean
}) {
  const carouselRef = createRef<HTMLDivElement>()
  const sliderRef = useRef<any>()
  //data props
  const carouselItems: TPromotions = data.carousel_items
  //state (we need to know index for slickGoTo)
  const [sliderInd, setSlideIndx] = useState(0)

  const website = useWebsite()

  //Mouse Scroll
  useEffect(() => {
    let slickListDiv = document.getElementsByClassName('slick-list')[0]

    //only works after save/hot reload
    const onScrollOrTouchpad = (e: any) => {
      // Mouse scrolls usually 120 wheelDeltaY across browsers (https://stackoverflow.com/a/62415754
      // && https://stackoverflow.com/questions/5527601/normalizing-mousewheel-speed-across-browsers)
      // we find out if mouse scroll or is it touchpad
      // COULD also work with just !e.wheelDeltaX, since mouse scrolls only Y axis,
      // but we don't wanna change slides with touchpad scrolling of Y (will scroll down page now, unlike mouse wheel)
      if (!e.wheelDeltaX && Math.abs(e.wheelDeltaY) === 120) {
        e.preventDefault()
        e.wheelDeltaY > 0
          ? sliderRef.current.slickNext()
          : sliderRef.current.slickPrev()
      }
    }
    slickListDiv.addEventListener(
      'wheel',
      (event: any) => onScrollOrTouchpad(event),
      false,
    )

    return () => slickListDiv.removeEventListener('wheel', onScrollOrTouchpad)
  }, [])

  //screen width setter
  const [containerWidth, setContainerWidth] = useState(0)
  useEffect(() => {
    const el = document.querySelector('#container-observer')

    if (!el) return

    const observer = new ResizeObserver(() => {
      setContainerWidth(el.clientWidth)
    })

    observer.observe(el)

    return () => {
      el && observer.unobserve(el)
    }
  }, [])

  // lazy load videos https://web.dev/lazy-loading-video/
  useEffect(() => {
    if (!carouselRef.current) return

    const lazyVideos = carouselRef.current.querySelectorAll('video')

    const videoObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source]
            if (
              typeof videoSource.tagName === 'string' &&
              videoSource.tagName === 'SOURCE'
            ) {
              /* @ts-ignore */
              videoSource.src = videoSource.dataset.src
            }
          }

          /* @ts-ignore */
          video.target.load()
          videoObserver.unobserve(video.target)
        }
      })
    })

    lazyVideos.forEach(function (lazyVideo) {
      videoObserver.observe(lazyVideo)
    })
  }, [])

  // Settings for the slider
  const settings = {
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 6000,
    afterChange: (current: any) => {
      setSlideIndx(current)
    },
  }

  return (
    <div
      ref={carouselRef}
      css={[
        css`
          > .slick-slider {
            max-height: 660px;
          }
          .slick-track {
            background: ${theme`colors.theme.secondary`};
          }

          + div + div + div {
            padding-bottom: 2rem;
          }
        `,
        data.chevron &&
          css`
            clip-path: polygon(
              100% 0,
              100% calc(100% - 8vw),
              50% 100%,
              0 calc(100% - 8vw),
              0% 0%
            );
            position: relative;
            z-index: 2;
          `,
          casinoNavigation && (
            data.chevron
              ? css`
              + div {
                margin-top: 8vw;
              }

              + div + div {
                margin-top: -8vw;
                position: relative;
                padding-top: clamp(4rem, 12vw, 12vw);
                z-index: 1;
              }
              `
              : css`
                + div {
                  margin-top: 4rem
                }

                + div + div {
                  margin-top: -8vw;
                  position: relative;
                  padding-top: clamp(4rem, 12vw, 12vw);
                  z-index: 1;
                }
              `
          )
      ]}>
      <div className="max-w-screen-xl m-auto h-0" id="container-observer"></div>
      <Slider {...settings} ref={sliderRef}>
        {/* CSS files for react-slick */}
        {carouselItems.map((slide) => {
          return (
            <div key={slide.id} tw="bg-black bg-opacity-20 h-[660px] relative">
              <div
                css={[
                  tw`grid grid-template-rows[660px] `,
                  css`
                    grid-template-columns: 90px ${containerWidth - 180}px 90px;
                    @media (min-width: ${theme`screens.lg`}) {
                      grid-template-columns: auto 90px ${containerWidth - 180}px 90px auto;
                    }
                  `,
                ]}>
                {carouselItems.length > 1 && (
                  <>
                    <button
                      onClick={() => sliderRef.current.slickPrev()}
                      tw="row-start-1 col-start-1 lg:col-start-2 m-auto pb-28 w-full flex justify-start md:justify-end lg:justify-center">
                      <svg
                        tw="h-10 md:h-15 lg:h-20"
                        xmlns="http://www.w3.org/2000/svg"
                        width="33.621"
                        height="64.243"
                        viewBox="0 0 33.621 64.243">
                        <path
                          id="Left_arrow"
                          data-name="Left arrow"
                          d="M30,0,0,30,30,60"
                          transform="translate(1.5 2.121)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => sliderRef.current.slickNext()}
                      tw="row-start-1 col-start-3 lg:col-start-4 m-auto pb-28 w-full flex justify-end md:justify-start lg:justify-center">
                      <svg
                        tw="h-10 md:h-15 lg:h-20"
                        xmlns="http://www.w3.org/2000/svg"
                        width="33.621"
                        height="64.243"
                        viewBox="0 0 33.621 64.243">
                        <path
                          id="Right_arrow"
                          data-name="Right arrow"
                          d="M0,0,30,30,0,60"
                          transform="translate(2.121 2.121)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                        />
                      </svg>
                    </button>
                  </>
                )}

                <div tw="col-start-2 lg:col-start-3 row-start-1 pt-10 pt-20 sm:pt-28 justify-self-center lg:justify-self-start lg:ml-10 flex flex-col items-center lg:items-start ">
                  <Heading
                    variant="h2"
                    css={[
                      tw`tracking-wider text-center lg:text-left lg:text-6xl leading-none mb-5`,
                      ['the-palm-beach', 'crockfords'].includes(website) &&
                        tw`text-theme-gold`,
                    ]}>
                    {slide.title}
                  </Heading>

                  {slide.summary ? (
                    <p
                      css={[
                        tw`max-w-2xl text-center lg:text-left mb-8 text-lg lg:(leading-none text-2xl font-thin tracking-tighter)`,
                        website === 'crockfords' && tw`lg:tracking-normal`,
                      ]}>
                      {slide.summary}
                    </p>
                  ) : null}
                  {slide.terms_and_conditions &&
                    slide.terms_and_conditions.summary && (
                      <div
                        css={[
                          tw`absolute bottom-0 left-0 p-4 w-full px-10 xl:px-0 bg-[rgba(35, 40, 48, .5)]`,
                          data.chevron && tw`pb-[8vw]`,
                        ]}>
                        <div tw="max-w-screen-xl mx-auto">
                          <TermsAndConditions
                            clip={slide.terms_and_conditions.clip}
                            summary={slide.terms_and_conditions.summary}
                            link={slide.terms_and_conditions.link}
                          />
                        </div>
                      </div>
                    )}
                  <div className="bg-opcity"></div>

                  {slide.link_title && slide.link ? (
                    <AHref
                      to={slide.link}
                      theme={
                        ['the-palm-beach', 'crockfords'].includes(website)
                          ? 'accent'
                          : 'default'
                      }
                      variant="inverse"
                      css={[
                        tw`mb-8 inline-block text-lg border-2 py-3 min-w-min px-10 flex-none justify-self-end leading-5 text-center tracking-wide
                              lg:(py-2 text-left w-auto text-base px-10)`,
                        website === 'genting-casinos' && tw`font-bold`,
                        ['the-palm-beach', 'crockfords'].includes(website) &&
                          tw`uppercase`,
                        website === 'crockfords' &&
                          tw`hover:(bg-transparent border-white text-white)`,
                      ]}>
                      {slide.link_title}
                    </AHref>
                  ) : null}

                  <ul className="flex sm:flex-nowrap list-none justify-center lg:justify-start">
                    {Array.apply(null, Array(carouselItems.length)).map(
                      (_, index) => (
                        <li
                          css={[
                            tw`my-4 w-8 h-0.5 mr-3 bg-white opacity-40 cursor-pointer`,
                            index === sliderInd && tw`w-9 opacity-80`,
                          ]}
                          key={index}
                          onClick={() => sliderRef.current.slickGoTo(index)}>
                          <span className="sr-only">{`Slide ${index + 1} of ${
                            carouselItems.length
                          }`}</span>
                        </li>
                      ),
                    )}
                  </ul>
                </div>

                <div tw="row-start-1 col-span-full z-[-1] relative">
                  {slide.type === 'video' ? (
                    <video
                      tw="absolute h-full object-cover w-full z-[-1]"
                      autoPlay
                      muted
                      loop
                      key={slide.id}
                      playsInline>
                      <source
                        data-src={slide.media.data.attributes.url}
                        type={slide.media.data.attributes.mime}
                        key={slide.media.data.attributes.id}
                      />
                    </video>
                  ) : (
                    <Image
                      tw="w-full h-full object-cover z-[-1]"
                      src={slide.media.data.attributes.url}
                      alt={
                        slide.media.data.attributes.alternativeText ||
                        'Promotional Image'
                      }
                      priority
                      layout="fill"
                      objectFit="cover"
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
