import tw from 'twin.macro'
import { useState } from 'react'
import { GamingTile } from '@/components/atoms/gaming-tile'
import { Heading } from '@/components/atoms/headings'
import { Container } from '@/components/atoms/container'
import { useWebsite } from '@/hooks/use-website'
import { Tile } from '@/types/tile'

type GamingSection = {
  title: string
  id: string
  data: Tile[]
}

export const GameSection = ({ title, id, data }: GamingSection) => {
  const [viewAll, setViewAll] = useState(false)
  const showViewAll = data.length > 6
  const dataToRender = viewAll ? data : data.slice(0, 6)
  const website = useWebsite()

  return (
    <Container id={id} tw="pt-5 relative mb-6" data-sectionId>
      <div tw="relative flex items-center justify-between mt-2 mb-6 flex-wrap">
        <Heading variant="h3"
          css={[
            website === 'the-palm-beach' && tw`font-sans!`
          ]}
        >{title}</Heading>
        {showViewAll ? (
          <button className="text-xl" onClick={() => setViewAll(!viewAll)}>
            {viewAll ? 'View Less' : 'View More'}
          </button>
        ) : null}
      </div>
      <div tw="mt-6 grid gap-3 grid-cols-[repeat(auto-fit, minmax(200px, 1fr))] lg:grid-cols-[repeat(auto-fit, minmax(196px, 196px))]">
        {dataToRender.map((tile) => (
          <GamingTile key={tile.id} {...tile}>
            <GamingTile.Heading tw="bg-theme-secondary h-full">
              {tile.attributes.title}
            </GamingTile.Heading>
          </GamingTile>
        ))}
      </div>
    </Container>
  )
}
