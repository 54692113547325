import tw, { css, theme } from 'twin.macro'
import { Tab } from '@headlessui/react'

import { IPlatform, TGame } from '@/types/gaming-information-types'

import { useWebsite } from '@/hooks/use-website'

import { GameInfoCard } from '@/atoms/game-info-card'
import { Slider, SliderItem } from '@/atoms/slider'
import { Container } from '@/atoms/container'
import { NextLink } from '@/atoms/next-link'

const styles = {
  tab: [
    tw`relative uppercase text-white text-center text-lg lg:text-2xl font-light focus:outline-none`,
    tw`whitespace-nowrap inline-block w-auto p-5 border-b-4 border-black`,
    tw`md:hover:(text-theme-gold border-theme-gold)`,
    css`
      @media (min-width: ${theme`screens.md`}) {
        &[aria-selected='true']:hover {
          color: #9a8757;
          border-color: #9a8757;
        }
      }
      ,
      &[aria-selected='true'] {
        color: white;
        font-weight: 400;
        border-color: white;
      }
      ,
      &::before {
        display: block;
        content: attr(data-name);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    `,
  ],
  tabList: [
    tw`flex md:mx-auto bg-transparent overflow-y-auto`,
    css`
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
      }
    `,
  ],
  sliderItem: [
    tw`flex-shrink-0`,
    css`
      width: calc(calc(100% / 1.2) - ${theme`gap.3`});
      @media (min-width: 500px) {
        width: calc(calc(100% / 1.5) - ${theme`gap.3`});
      }
      @media (min-width: 600px) {
        width: calc(calc(100% / 2.5) - ${theme`gap.3`});
      }
      @media (min-width: ${theme`screens.md`}) {
        width: calc(calc(100% / 3.5) - ${theme`gap.3`});
      }
      @media (min-width: ${theme`screens.lg`}) {
        width: calc(calc(${theme`screens.lg`} / 3.8%) - ${theme`gap.3`});
      }
      @media (min-width: ${theme`screens.xl`}) {
        width: calc(calc(${theme`screens.xl`} / 4.8) - ${theme`gap.3`});
      }
    `,
  ],
}

export function PlatformTabPanels({ platform }: { platform: IPlatform }) {
  const website = useWebsite()

  return (
    <>
      {platform.casino_games_categories &&
        platform.casino_games_categories?.data?.length > 0 && (
          <Container key={platform.id} tw="mr-0 md:mx-0 md:ml-5 xl:mx-auto">
            <Tab.Group as="div" tw="flex justify-center block">
              <div tw="flex justify-center">
                <Tab.List css={styles.tabList}>
                  <div key={'allCat'} css={styles.tab}>
                    <NextLink
                      to={`${
                        website !== 'genting-casinos' ? `/${website}` : ''
                      }/casino-gaming`}>
                      All
                    </NextLink>
                  </div>
                  {platform.casino_games_categories?.data?.map(
                    ({
                      id,
                      attributes: { title },
                    }: {
                      id: number
                      attributes: { title: string }
                    }) => (
                      <Tab key={id} data-name={title} css={styles.tab}>
                        {title}
                      </Tab>
                    ),
                  )}
                </Tab.List>
              </div>

              <Tab.Panels className="overflow-y-hidden">
                {platform.casino_games_categories?.data?.map((category) => (
                  <Tab.Panel key={category.id} tw="max-w-screen-xl mx-auto">
                    <Slider
                      showScrollBar={false}
                      tw="py-12 flex space-x-10 w-full justify-between overflow-x-auto overscroll-x-contain"
                      css={[
                        css`
                          padding-right: 0.5rem;

                          @media (max-width: ${theme`screens.xl`}) {
                            &::after {
                              content: '';
                              padding-right: 1.25rem;
                            }
                          }
                        `,
                      ]}>
                      {category.attributes.casino_games.data?.map(
                        (game: TGame) => (
                          <SliderItem
                            tabIndex={0}
                            css={styles.sliderItem}
                            tw="h-[220px] bg-theme-gold flex-shrink-0">
                            <GameInfoCard game={game} />
                          </SliderItem>
                        ),
                      )}
                    </Slider>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </Container>
        )}
    </>
  )
}
