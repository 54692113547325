//@ts-nocheck
import tw from 'twin.macro'
import { Heading } from '@/components/atoms/headings'
import { Container } from '@/atoms/container'
import { TBenefitsHeading, TGentingBenefits } from '@/types/homepage'
import Image from 'next/image'
import { Slider } from '@/atoms/slider'
import { RenderMarkdown } from '@/lib/render-markdown'
import { NextLink } from '@/atoms/next-link'

const styles = {
  backgroundContainer: tw`bg-theme-accent bg-diamonds-pattern py-20 text-theme-primary`,
  content: [
    tw`text-left py-5 flex flex-col justify-center items-center`,
    tw`lg:(m-auto grid grid-cols-12)`,
  ],
  innerHeading: [
    tw`flex flex-col items-center mb-5`,
    tw`lg:(col-span-4 items-start mb-20 self-start mt-2)`,
  ],
  title: [
    tw`uppercase font-light tracking-widest leading-14 text-center`,
    tw`lg:(text-2xl text-left)`,
  ],
  separator: [tw`my-10 w-10/12`, tw`lg:(my-5 w-64)`],
  description: [
    tw`text-white font-light w-10/12 text-lg text-center`,
    tw`lg:(w-auto text-left mr-32)`,
  ],
  benefit: tw`w-full h-full text-center overflow-visible`,
  benefitImg: tw`mb-2 mx-auto w-40 h-36 lg:w-28 lg:h-24 relative`,
  benefitDesc: tw`text-lg md:font-light md:text-base`,
}

function GentingBenefits({ data }: { data: any }) {
  const benefitEntries = data.benefits.map((entry) => {
    return { ...entry, image: entry.image.data.attributes }
  })

  const { heading, heading_copy: description }: TBenefitsHeading =
    data.benefits_heading
  const { cta } = data
  return (
    <div css={styles.backgroundContainer}>
      <Container tw="mr-0 md:mx-0 md:ml-5 xl:mx-auto">
        <Heading variant="h1" tw="text-center hidden mb-16 lg:block">
          My Genting
        </Heading>

        <div css={styles.content}>
          <div css={styles.innerHeading}>
            <Heading css={styles.title}>{heading}</Heading>

            <hr css={styles.separator} aria-hidden />

            <div css={styles.description}>
              <RenderMarkdown>{description}</RenderMarkdown>
            </div>
            {cta && (
              <NextLink
                to={cta?.link}
                tw="p-2 mt-10 mb-8 block text-center border 
              focus:(outline-none ring-2 ring-red-500 border-red-500) cursor-pointer min-w-[200px]">
                {cta?.title}
              </NextLink>
            )}
          </div>

          <Slider tw="flex w-full m-auto px-4 space-x-5 md:space-x-10 lg:px-0 lg:w-auto lg:col-span-7 lg:col-start-5 lg:ml-12 overflow-x-auto overscroll-x-contain pb-20">
            {benefitEntries.map((benefit) => {
              return (
                <div key={benefit.id} css={styles.benefit}>
                  <div css={styles.benefitImg}>
                    {benefit && benefit.image ? (
                      <Image
                        src={benefit.image.url}
                        alt={benefit.image.alternativeText || 'Benefit Image'}
                        layout="fill"
                        objectFit="contain"
                        loading="lazy"
                      />
                    ) : null}
                  </div>

                  <Heading as="h3" variant="h4" tw="mb-2">
                    {benefit.title}
                  </Heading>
                  <p css={styles.benefitDesc}>
                    <RenderMarkdown>{benefit.description}</RenderMarkdown>
                  </p>
                </div>
              )
            })}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export { GentingBenefits }
