import tw, { css, theme } from 'twin.macro'

import { Rewards as RewardsTypes } from '@/types/rewards'

import { useMediaQuery } from '@/hooks/use-media-query'

import { RenderMarkdown } from '@/lib/render-markdown'

import { Slider, SliderItem } from '@/atoms/slider'
import { Heading } from '@/atoms/headings'
import { RewardCard } from '@/atoms/reward-card'
import { Container } from '@/atoms/container'
import { NextLink } from '@/atoms/next-link'

const styles = {
  container: tw`py-16 bg-theme-secondary`,
  pattern: [
    tw`bg-auto bg-lines-pattern-mobile md:bg-lines-pattern-desktop md:bg-cover bg-no-repeat bg-opacity-10 pb-24 pt-18`,
    css`
      background-position: right bottom -75px;
    `,
  ],
  slider: ({
    isLargeScreen,
    itemsLength,
  }: {
    isLargeScreen: boolean
    itemsLength: number
  }) => [
    tw`py-16 flex -mx-3 overflow-y-auto`,
    isLargeScreen && itemsLength <= 4 && tw`!overflow-x-hidden`,
  ],
  sliderItem: [
    tw`flex-shrink-0 mx-3 (width[calc(100vw - 40%)] )
    `,
    css`
      @media (min-width: ${theme`screens.md`}) {
        width: calc(calc(100% / 3) - ${theme`gap.5`});
      }
      @media (min-width: ${theme`screens.xl`}) {
        width: calc(calc(${theme`screens.xl`} / 4) - ${theme`gap.5`});
      }
    `,
  ],
}

const Rewards = ({ data }: { data: RewardsTypes }) => {
  const isLargeScreen = useMediaQuery(`(min-width: ${theme('screens.lg')})`)
  const { reward_schemes, terms_and_conditions, terms_and_conditions_link } = data

  return (
    <section tw="bg-gradient-to-b from-theme-accent to-theme-gradient-dark">
      <Container variant="full" css={styles.pattern}>
        <Container tw="relative">
          <>
            <Heading
              variant="h2"
              tw="max-w-xs md:max-w-md xl:max-w-screen-xl text-white mt-3 text-4xl lg:(mt-4 text-4.5xl)">
              {data.heading}
            </Heading>
            <Slider
              css={styles.slider({
                isLargeScreen,
                itemsLength: reward_schemes.data.length,
              })}>
              {reward_schemes &&
                reward_schemes.data.map((item) => (
                  <SliderItem key={item.id} css={styles.sliderItem}>
                    {/*@ts-ignore*/}
                    <RewardCard reward={item.attributes} />
                  </SliderItem>
                ))}
            </Slider>
            <p className="mt-8 text-xs">
              <RenderMarkdown>{terms_and_conditions}</RenderMarkdown>
              <NextLink to={terms_and_conditions_link}>
                  See Full  T&C&apos;s here
              </NextLink>
            </p>
          </>
        </Container>
      </Container>
    </section>
  )
}

export { Rewards }
